@import './../../../../scss/colors';

.paginationIcon {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: 40px auto;
  background-position: center;
  background-repeat: no-repeat;

  span {
    font-size: 0;
    color: transparent;
  }

  &.IconNext {
    background-image: url('./images/icon--pagination-next.svg');
    margin-left: -4px;

    &.active,
    &.selected {
      background-image: url('./images/icon--pagination-next-selected.svg');
    }

    &:hover,
    &:focus {
      background-image: url('./images/icon--pagination-next-hover.svg');
    }
  }

  &.IconPrevious {
    background-image: url('./images/icon--pagination-previous.svg');
    margin-right: -4px;

    &.active,
    &.selected {
      background-image: url('./images/icon--pagination-previous-selected.svg');
    }

    &:hover,
    &:focus {
      background-image: url('./images/icon--pagination-previous-hover.svg');
    }
  }

  &.IconNextDisabled {
    background-image: url('./images/icon--pagination-next-disabled.svg');
    pointer-events: none;
    margin-left: -4px;
  }

  &.IconPreviousDisabled {
    background-image: url('./images/icon--pagination-previous-disabled.svg');
    pointer-events: none;
    margin-right: -4px;
  }
}

.pagination-wrapper .pagination li {
  display: inline;
  margin: 0px 12px;
  --bs-pagination-padding-x: 0rem;
  --bs-pagination-bg: none;
  --bs-pagination-color: $ts-background-on-primary-1;

  &.active {
    --bs-pagination-padding-x: 0rem;
    padding: 0rem !important;
    --bs-pagination-bg: none;
  }
}

.pagination-wrapper .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding: 1rem 0;
  margin: 0 auto;
  height: 45px;
}

.pagination-wrapper {
  .pagination {
    li.page-number {
      margin: 0px 16px;
      &:hover,
      &:focus,
      &.active {
        a.page-link {
          color: $ts-color-violet-75;
          background: none;
          text-decoration: none !important;
        }
      }
    }
  }
  &.mobile {
    .pagination {
      li {
        margin: 0 12px;
      }
    }
  }
}
