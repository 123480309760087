@import './../../../../scss/colors';
@import './../../../../scss/mixins';

.accordion-section-default {
  text-align: center;
  padding: 3.75rem 5rem 0;
  background-color: $ts-background-primary;
  @include respond-above(md) {
    padding: 3.75rem 5rem 0;
  }
  @include mobile {
    padding: 3.75rem 1rem 0;
  }
  &.secondary {
    background-color: $ts-background-secondary;
  }
  .title {
    margin: 0 auto;
    font-size: 1.5rem;
    font-weight: 600;
    font-family: "Inter", Helvetica, Arial, "Droid Sans", "Arimo", sans-serif;;
  }
  p {
    margin: 1.5rem 0 2.5rem;
  }
  .accordion-block {
    position: relative;
    text-align: left;
    border-bottom: 0.0625rem solid $ts-color-gray-50;
    padding: 1.25rem 2rem 1.25rem 1.25rem;
    &.active {
      .icon {
        background: none;
        border: none;
        right: 1.25rem;
        top: 0.25rem;
      &:after {
        transform: rotate(180deg);
      }
      }
      .accordion-body {
        display: block;
      }
    }
    &:first-child {
      border-top: 0.0625rem solid $ts-color-gray-50;
    }
    .icon {
      background: none;
      border: none;
      position: absolute;
      font-size: 1.95rem;
      right: 1.25rem;
      top: 0.25rem;
      cursor: pointer;
      @include respond-above(md) {
        top: 0.625rem;
      }
      &:after {
        display: block;
        content: '';
        background-image: url('../../../static/icons/icon--arrow-down.svg');
        background-size: 1rem;
        background-repeat: no-repeat;
        width: 1rem;
        height: 1rem;
        margin-top: 1.25rem;
        @include respond-above(md) {
          background-size: 1.125rem;
          width: 1.125rem;
          height: 1.125rem;
          margin-top: 1.125rem;
        }
      }
    }
    .accordion-title {
      @include headline(small-bold);
      padding: 0;
      margin: 0;
      cursor: pointer;
    }
    .accordion-body {
      display: none;
      margin-top: 1rem;
      * {
        margin: 0;
        padding: 0;
        margin-bottom: 1rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
      h1,
      h2,
      h3,
      h4 {
        @include headline(small);
      }
      h5 {
        @include headline(small-bold);
      }
      h6 {
        @include headline(uppercase);
      }
      ul,
      ol {
        padding-left: 2rem;
      }
    }
  }
}
