@import './../../../../../../scss/colors';
@import './../../../../../../scss/mixins';

.subNavComponent {
  &:not(.mobileNav) {
    &.aboutus {
      .subNavComponent {
        &__inner {
          max-width: calc(1280px + 2rem);
          @media (min-width: 992px) and (max-width: 1250px) {
            display: grid;
            grid-template-columns: 1fr;
          }
        }

        &__left {
          justify-content: center;
          @media (min-width: 992px) and (max-width: 1250px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 100%;
            max-width: 100%;
          }
        }

        &__right {
          max-width: 864px;
          padding-left: 1rem;
          @media (min-width: 992px) and (max-width: 1250px) {
            width: 100%;
            max-width: 100%;
            margin-top: 2rem;
            padding: 0;
          }
        }

        &__list {
          grid-template-columns: repeat(3, 1fr);
          width: 100%;
          @media (min-width: 992px) and (max-width: 1250px) {
            grid-template-columns: repeat(3, 1fr);
          }
        }

        &__title {
          h2 {
            margin-bottom: 0;
          }

          @media (min-width: 992px) and (max-width: 1250px) {
            margin-bottom: 1.5rem;
            br {
              display: none;
            }
          }
        }

        &__cta {
          justify-content: space-between;
          width: 100%;

          @media (min-width: 992px) and (max-width: 1250px) {
            justify-content: flex-end;
            gap: 1rem;
            .button {
              margin-top: 0;
              margin-bottom: 1.5rem;
            }
          }
          @media (992px <= width <= 1199px) {
            .button {
              margin-top: 0;
              margin-bottom: 1.5rem;
            }
          }
        }

        .subNavComponent__contact_cta {
          padding-top: 9px;
          padding-bottom: 9px;
          line-height: 1;
        }
      }

      .link_card {
        position: relative;
        isolation: isolate;
        min-height: 270px;

        .card-body {
          display: flex;
          flex-direction: column;
          justify-content: stretch;
          width: 100%;
          height: 100%;
          padding: 1.875rem;

          h3 {
            @include headline(small);
            color: $ts-container-on-container-primary-1;
            line-height: 1.2;
          }

          p {
            margin: 0;
            font-size: 0.75rem;
            color: $ts-container-on-container-primary-2;
            padding-bottom: 12px;
          }

          .button {
            width: fit-content;
            font-size: 1rem;
            padding: 0;
            margin-top:auto ;
            &::before{
              background-color: $black;
              height: 0.0625rem;
              bottom: -0.0625rem;
            }
            &:hover:before {
              background-color: $ts-accent-1-primary;
              height: 0.25rem;
              bottom: -0.25rem;
          }
          }

          &--icon {
            display: block;
            width: 1.25rem;
            margin-bottom: 0.875rem;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            @include aspect-ratio(1, 1);

            &.icon--right-arrows {
              background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='15' viewBox='0 0 20 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_2815_2590)'%3E%3Cpath d='M12.7453 14.8098C12.4107 14.8098 12.0947 14.6761 11.8592 14.4374C11.6224 14.1945 11.4927 13.8734 11.4927 13.5359C11.4927 13.1944 11.6224 12.8743 11.8592 12.633L16.976 7.42286L11.8592 2.21207C11.6224 1.96989 11.4927 1.65211 11.4927 1.30978C11.4927 0.969962 11.6224 0.65134 11.8592 0.408492C12.3307 -0.0723619 13.1585 -0.0723619 13.6291 0.408492L19.6344 6.5199C20.1218 7.01795 20.1218 7.8271 19.6344 8.32432L13.6291 14.4374C13.3929 14.6761 13.0777 14.8098 12.7453 14.8098Z' fill='%235C4BB9'/%3E%3Cpath d='M6.99877 14.8098C6.66482 14.8098 6.34957 14.6761 6.11333 14.4374C5.8748 14.1945 5.74512 13.8734 5.74512 13.5359C5.74512 13.1944 5.8748 12.875 6.11333 12.633L11.2292 7.42286L6.11334 2.21207C5.8748 1.97323 5.74512 1.65294 5.74512 1.30978C5.74512 0.969962 5.8748 0.65134 6.11334 0.408492C6.58646 -0.0723619 7.41174 -0.0723619 7.88569 0.408492L13.8885 6.5199C14.3767 7.01795 14.3767 7.8271 13.8885 8.32432L7.88569 14.4374C7.64863 14.6777 7.33337 14.8098 6.99877 14.8098Z' fill='%235C4BB9'/%3E%3Cpath d='M1.25119 14.8098C0.919044 14.8098 0.602803 14.6761 0.367383 14.4374C0.129669 14.1945 0 13.8734 0 13.5359C0 13.1944 0.129672 12.8743 0.367383 12.633L5.48494 7.42286L0.367383 2.21207C-0.12165 1.71403 -0.12165 0.907205 0.367383 0.408492C0.839697 -0.0723619 1.66661 -0.0723619 2.1381 0.408492L8.14257 6.5199C8.62931 7.01945 8.62931 7.8271 8.14257 8.32432L2.1381 14.4374C1.90351 14.6761 1.58661 14.8098 1.25119 14.8098Z' fill='%235C4BB9'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2815_2590'%3E%3Crect width='20' height='14.7619' fill='white' transform='translate(0 0.0478516)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
            }

            &.icon--expand {
              background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_2815_2607)'%3E%3Cpath d='M1.04432 9.10193C0.468592 9.10193 0 8.63334 0 8.05731V1.47303C0 0.897303 0.468592 0.428711 1.04432 0.428711H7.62829C8.20432 0.428711 8.67291 0.897303 8.67291 1.47303C8.67291 2.04906 8.20432 2.51795 7.62829 2.51795H2.08894V8.0573C2.08894 8.63333 1.62035 9.10193 1.04432 9.10193Z' fill='%235C4BB9'/%3E%3Cpath d='M17.9763 19.7789H11.392C10.8163 19.7789 10.3477 19.3103 10.3477 18.7346C10.3477 18.1585 10.8163 17.6899 11.392 17.6899H16.9316V12.1503C16.9316 11.5745 17.4002 11.106 17.9763 11.106C18.5523 11.106 19.0206 11.5745 19.0206 12.1503V18.7346C19.0206 19.3103 18.5523 19.7789 17.9763 19.7789Z' fill='%235C4BB9'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2815_2607'%3E%3Crect width='19.0208' height='19.35' fill='white' transform='translate(0 0.428711)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
            }

            &.icon--plus {
              background-image: url("data:image/svg+xml,%0A%3Csvg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.00121 16.9598C8.43193 16.9598 7.96965 16.4982 7.96965 15.9301V9.97609H2.01604C1.44812 9.97609 0.98584 9.5145 0.98584 8.94522C0.98584 8.37786 1.44812 7.9149 2.01604 7.9149H7.96965V1.96084C7.96965 1.39294 8.43193 0.930664 9.00121 0.930664C9.56845 0.930664 10.0302 1.39294 10.0302 1.96084V7.9149H15.9836C16.5523 7.9149 17.0145 8.37786 17.0145 8.94522C17.0145 9.5145 16.5523 9.97609 15.9836 9.97609H10.0302V15.9301C10.0302 16.4982 9.56845 16.9598 9.00121 16.9598Z' fill='%235C4BB9' /%3E%3C/svg%3E%0A");
            }
          }
        }
      }
    }
  }

  .subNavComponent {
    &__cta {
      button.secondary-button.signpost {
        .arrow-icon {
          width: 1.75rem;
          height: 1.75rem;
          background-size: 1.75rem;
        }
        &:hover,
        &:focus {
          .arrow-icon {
            width: 2.5rem;
            height: 2.5rem;
            background-size: 2.5rem;
          }
        }
      }
    }
  }
}
