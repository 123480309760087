@import '../../../scss/mixins';
@import '../../../scss/colors';
@import '../../../scss/variables';

h1,
h2,
h3,
h4,
h5,
h6 {
  // color: $ts-background-on-primary-1;
  font-family: $font-family-1;
  font-style: normal;
  font-weight: 400;

  &.on-secondary,
  &.reversed {
    color: $ts-background-on-secondary-1;
  }
}

h1 {
  font-size: 4rem;
  line-height: 106%;
  letter-spacing: -3.5px;

  @include mobile {
    font-size: 3rem;
    line-height: 108%;
    letter-spacing: -2.5px;
  }
}

h2 {
  font-size: 2.5rem;
  line-height: 110%;
  letter-spacing: -1.5px;

  @include mobile {
    font-size: 2rem;
    line-height: 112%;
    letter-spacing: -0.5px;
  }
}

h3 {
  font-size: 2.25rem;
  line-height: 111%;
  letter-spacing: -1px;

  @include mobile {
    font-size: 1.75rem;
    line-height: 60%;  //do not change
    letter-spacing: -0.5px;
  }
}

h4 {
  font-size: 2rem;
  line-height: 112%;
  letter-spacing: -0.5px;

  @include mobile {
    font-size: 1.5rem;
    line-height: 117%;
    letter-spacing: -0.5px;
  }
}

h5 {
  font-size: 1.75rem;
  line-height: 114%;
  letter-spacing: -0.5px;
  text-transform: none;

  @include mobile {
    font-size: 1.25rem;
    line-height: 120%;
    letter-spacing: -0.5px;
  }
}

h6 {
  font-size: 1rem;
  /* 16px */
  line-height: 1.5;
  font-weight: 400;

  &.light {
    font-weight: 300;
  }
}

p {
  //styleName: TS/Body Copy/Body Medium;
  color: $ts-background-on-primary-1;
  font-family: $font-family-2;
  font-size: 1rem;
  line-height: 1.375;
  margin: 1.5rem 0;
  font-weight: 400;
  letter-spacing: 0px;

  &.small {
    //styleName: TS/Body Copy/Body Small;
    font-size: 0.875rem;
    line-height: 1.42;
  }

  &.fine {
    //styleName: TS/Body Copy/Body Fine Print;
    font-size: 0.75rem;
    line-height: 1.66;
  }

  &.on-secondary,
  &.reversed {
    color: $white;
  }

  &.on-primary-2 {
    color: $ts-background-on-primary-2;
  }

  &.on-secondary-2 {
    color: $ts-background-on-secondary-2;
  }
}

a {
  // color: $ts-color-blue-100;
  text-decoration: underline;
  background-color: transparent;
  line-height: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  transition: $btn-transition;

  // &:hover {
  //   color: $ts-color-blue-50;
  // }
}

.headline {
  @include headline(medium);

  &.small {
    @include headline(small);
  }

  &.bold {
    font-weight: 600;
  }

  &.uppercase {
    text-transform: uppercase;
  }
}

.label {
  @include label(medium);

  &.light {
    @include label(light);
  }

  &.small {
    @include label(small);
  }

  &.bold {
    font-weight: 600;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.underline {
    text-decoration: underline;
  }
}
