@import './../../../../scss/mixins';

.carousel.twi-relatedInsights.insights {
    padding: 5rem 5rem 5rem;
    @include respond-below(sm){
      padding: 3.75rem 0 3.75rem 1.25rem !important;
    }

    &.primary {
      background-color: #FFFFFF;

      .card-content-carousel .title.twi-featured-title{
        color: #000;
      }

      .slick-dots li .pagination .active {
        color: #000 !important;
      }
    }

    &.secondary {
      background-color: #000;
      color: #FFFFFF;

      .card-content-carousel .title.twi-featured-title, .card-content-carousel .mobtitle{
        color: #fff;
      }

      .slick-dots li .pagination .active {
        color: #FFFFFF !important;
      }
    }

    .card-body.card-body-carousel{
      @include respond-above(sm){
        .cttagcontainer{
          padding-bottom: 20px;
        }
        .card-content-carousel{
          background-color: transparent;
          .twi-featured-title{
            margin-bottom: 12px;
          }
        }
      }
    }

    .card.card__insights.featured{
      .card-body{
        padding: 32px 24px 24px 24px;

        .cttagcontainer{
          margin: 0;
          margin-bottom: 24px;
        }
      }
    }

  .section-header .title{
    font-size: 32px;
    font-weight: 400;
    line-height: 36px; 
    letter-spacing: -0.5px;
  }
  
  .slick-arrow{
    @include respond-below(sm){
      top: 29rem !important;
    }
    @include respond-above(sm){
      top: -4.5rem !important;
    }
  
    &.slick-next {
      right: 2.25rem;
    }
    &.slick-prev{
      right: 5rem;
    }
  }
  .slick-dots {
    right: 9rem;
    @include respond-above(sm){
      top: -4.5rem;
    }
  }

}
.card-footer-carousel {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    padding: 0;
  
    &.secondary{
      background-color: transparent;
      a{
        button.button {
          color: #FFFFFF;
          border-bottom: 0.125rem solid #FFFFFF !important;
        }
      }
    }
}


.card-body.related-insights-carousal .card-content-carousel .title.twi-featured-title {
overflow: visible;
}
.card-content-carousel.primary  .para.twi-featured-para div p{
color: #000;
}
.card-body.related-insights-carousal .card-content-carousel .para.twi-featured-para .rich-text-alert{
max-height: 55px;
}