@import './../../../scss/colors';
@import './../../../scss/mixins';
@import './../../../scss/variables';

$btn-transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);

.buttonhref-anchor {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
.button {
  color: $ts-action-primary;
  position: relative;
  display: inline-flex;
  align-items: center;
  position: relative;
  font-family: $font-inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px !important;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  transition: $btn-transition;
  padding: 6px 6px 6px 20px;
  &.related {
    font-size: 16px;
  }

  @include mobile {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.02em;
  }
  a {
    text-decoration: none;
    font-size: 1.25rem;
    font-weight: 400;
    text-align: center;
    line-height: 1;
  }
  &:disabled {
    color: $ts-action-on-disabled-primary;
    background-color: $ts-action-disabled-primary;
  }
  &:focus,
  &:focus-visible,
  &:focus-within {
    outline-color: $ts-accent-1-primary;
  }
  &.icon__right {
    flex-direction: row-reverse;
  }
  .icon {
    &__left {
      margin: 0 1rem 0 0;
      @include mobile {
        margin: 0 0.75rem 0 0;
      }
    }
    &__right {
      margin: 0 0 0 1rem;
      @include mobile {
        margin: 0 0 0 0.75rem;
      }
    }
  }
  .arrow-icon {
    flex-shrink: 0;
  }
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
  &.primary-button {
    background-color: $ts-action-primary;
    border: 0;
    border-radius: 2.5rem;
    color: $ts-action-on-action-primary;
    letter-spacing: 0em;
    line-height: 1;
    padding: 0.75rem 1.25rem;
    z-index: 1;
    @include mobile {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.25;
      letter-spacing: -0.02em;
      padding: 0.625rem 1rem;
    }
    &:before {
      opacity: 0;
      content: '';
      background-color: $ts-action-primary;
      width: calc(100% + 0.5rem);
      position: absolute;
      height: calc(100% + 0.5rem);
      top: -0.25rem;
      left: -0.25rem;
      border-radius: 2.5rem;
      border: 0.25rem solid $ts-action-primary;
      transition: $btn-transition;
      z-index: -1;
    }
    &:hover {
      &:before {
        opacity: 0.2;
      }
    }
    &.white {
      color: $ts-action-on-action-secondary;
      background-color: $ts-action-secondary;
      &:before {
        border-color: $ts-action-secondary;
        background-color: $ts-action-secondary;
      }
      &:hover {
        &:before {
          opacity: 0.4;
        }
      }
    }
    &.violet {
      color: $ts-action-on-action-primary;
      background-color: $ts-accent-1-primary;
      &:before {
        border-color: $ts-accent-1-primary;
        background-color: $ts-accent-1-primary;
      }
      &:hover {
        &:before {
          opacity: 0.4;
        }
      }
    }
    &.signpost {
      padding-right: 3rem;
      height: 3rem;
      &:before {
        display: none;
      }
      .arrow-icon {
        @include signPost(white);
      }
      &:hover {
        .arrow-icon {
          @include signPost(lightGray);
          right: -0.125rem;
          width: 2.75rem;
          height: 2.75rem;
          background-size: 2.75rem;
        }
      }
      &:disabled {
        @include signPost(gray);
      }
      &.white {
        .arrow-icon {
          @include signPost(black);
        }
        &:hover {
          .arrow-icon {
            @include signPost(darkGray);
            right: -0.125rem;
            width: 2.75rem;
            height: 2.75rem;
            background-size: 2.75rem;
          }
        }
      }
      &.violet {
        &:hover {
          .arrow-icon {
            @include signPost(violet);
            right: -0.125rem;
            width: 2.75rem;
            height: 2.75rem;
            background-size: 2.75rem;
          }
        }
      }
    }
    &.small {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.25;
      letter-spacing: -0.02em;
    }
  }
  &.secondary-button {
    background-color: $ts-action-secondary;
    border: 0.1875rem solid transparent;
    border-radius: 2.5rem;
    color: $ts-action-on-action-secondary;
    letter-spacing: 0em;
    line-height: 1;
    padding: 0.75rem 1.25rem;
    z-index: auto;
    outline: none;
    box-shadow: 0 0 0 0.0625rem $ts-action-on-disabled-primary inset;
    &.blue{ 
      border: 0.1875rem solid #56bded;
      box-shadow: none;
    }
    @include mobile {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.25;
      letter-spacing: -0.02em;
      padding: 0.625rem 1rem;
    }
    &:hover {
      outline: none;
      border: 0.1875rem solid $ts-outline-on-primary-border-hover;
      box-shadow: 0 0 0 0.0625rem $ts-outline-on-primary-border-hover inset;
    }
    &.white {
      color: $ts-action-on-action-primary;
      background-color: transparent;
      &:before {
        border-color: $ts-action-secondary;
        background-color: $ts-action-secondary;
      }
      &:hover {
        outline: none;
        border: 0.1875rem solid $ts-outline-on-secondary-border-hover;
        box-shadow: 0 0 0 0.0625rem $ts-outline-on-secondary-border-hover inset;
        &:before {
          opacity: 0.4;
        }
      }
    }
    &.violet {
      color: $black;
      background-color: $ts-accent-1-primary;
      z-index: auto;
      &:active {
        color: $ts-action-on-action-primary;
        background-color: $ts-accent-1-primary;
      }
      &:hover {
        outline: none;
        border: 0.1875rem solid $ts-outline-on-secondary-border-hover;
        box-shadow: 0 0 0 0.0625rem $ts-outline-on-secondary-border-hover inset;
      }
    }
    &.signpost {
      padding-right: 3rem;
      &:before {
        display: none;
      }
      .arrow-icon {
        @include signPost(black);
      }
      &:hover {
        .arrow-icon {
          // @include signPost(violet);
          right: 0;
          width: 2.5rem;
          height: 2.5rem;
          background-size: 2.5rem;
        }
      }
      &:disabled {
        @include signPost(gray);
      }
      &.violet {
        &:hover {
          .arrow-icon {
            @include signPost(violet);
            right: -0.125rem;
            width: 2.5rem;
            height: 2.5rem;
            background-size: 2.5rem;
          }
        }
      }
      &.white {
        .arrow-icon {
          @include signPost(white);
        }
        &:hover {
          .arrow-icon {
            // @include signPost(violet);
            right: 0;
            width: 2.5rem;
            height: 2.5rem;
            background-size: 2.5rem;
          }
        }
      }
    }
    &.small {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.25;
      letter-spacing: -0.02em;
    }
    &.filtwhite {
      background: #000000;
      color: #ffffff;
    }
    &.search {
      border: none;
    }
  }
  /* TEXT BUTTON */
  &.text-button {
    background-color: transparent;
    border: 0;
    padding: 0;
    padding: 0.5rem 0 0.125rem;
    @include mobile {
      padding: 0;
      border-bottom: 1px solid $ts-action-primary;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: -0.0625rem;
      width: 100%;
      height: 0.0625rem;
      transition: $btn-transition;
      background-color: $ts-action-primary;
    }
    &:hover {
      &:before {
        background-color: $ts-accent-1-primary;
        height: 0.25rem;
        bottom: -0.25rem;
      }
    }
    &:disabled {
      color: $ts-action-on-disabled-primary;
      &:before {
        background-color: $ts-action-on-disabled-primary;
      }
    }
    &.white {
      color: $ts-action-on-action-primary;
      &:before {
        background-color: $ts-action-secondary;
      }
      &:hover {
        &:before {
          background-color: $ts-accent-1-primary;
        }
      }
    }
    &.signpost {
      .arrow-icon {
        @include arrow(black);
      }
      &.white {
        color: $ts-action-on-action-primary;
        .arrow-icon {
          @include arrow(white);
        }
      }
      &:active {
        // color: $ts-accent-1-primary;
        &:before {
          background-color: $ts-accent-1-primary;
        }
        .arrow-icon {
          @include arrow(violet);
        }
      }
      &:disabled {
        color: $ts-action-on-disabled-primary;
        &:before {
          background-color: $ts-action-on-disabled-primary;
        }
        .arrow-icon {
          @include arrow(gray);
        }
      }
    }
    &.small {
      font-size: 1rem;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.02em;
    }
    &.search {
      border-bottom: none;
    }
  }
  &.nav-button {
    padding: 0.5rem 0.75rem;
    border-width: 0.0625rem;
    border-style: solid;
    border-color: transparent;
    border-radius: 1.25rem;
    outline: none;
    font-size: 1rem;
    font-weight: 400;
    color: $ts-action-primary;
    text-decoration: none;
    line-height: 1.5rem;
    transition: color 0.3s ease-out, 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);

    &.mobile {
      &:hover {
        color: $contact-mobile-text-color !important;
      }
    }

    &:hover {
      // border-color: $ts-color-violet-100;
      // background-color: $ts-color-violet-100;
      color: $ts-action-secondary;
    }

    &:focus,
    &:focus-visible,
    &:focus-within,
    &:active,
    &.active {
      border: 0.0625rem solid $ts-color-violet-100;
      background-color: $ts-color-violet-100;
      color: $ts-action-secondary;
      transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    // @media screen and (min-width: 992px) and (max-width: 1199px) {
    @include respond-between(md, lg) {
      font-size: 0.875rem;
      margin-right: -0.5rem;
    }
  }
  &.button-new {
    background-color: $form-white-color;
    border: 0.0625rem solid $contact-button-border-color;
    border-radius: 1.25rem;
    height: 35px;
    &:hover {
      background-color: $contact-button-hover-bgcolor;
      border: 0.0625rem solid $contact-button-hover-bgcolor;
      color: $form-white-color;
      transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
  &.button-new-media {
    background-color: $form-white-color;
    padding: 0;
    font-size: 1.25rem;
    color: $contact-mobile-text-color;
    font-weight: 400;
  }
  &.tag {
    @include label(medium);
    display: flex;
    align-items: center;
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
    color: $ts-action-on-small-primary;
    background-color: $ts-action-small-primary;
    border-radius: 2.5rem;
    border: 1px solid transparent;
    .icon {
      max-height: 1rem;
      margin-right: 0.75rem;
      margin-left: -0.25rem;
      &.no-left-margin {
        margin-left: 0;
      }
    }
    &.outline {
      border: 1px solid $ts-action-outline-primary;
      background-color: transparent;
      &.white {
        color: $ts-action-secondary;
        border: 1px solid $ts-action-outline-secondary;
      }
    }
    &.small {
      @include label(small);
      font-size: 0.75rem !important; //Do not change
      line-height: 1rem;
      .icon {
        max-height: 0.75rem;
        margin-right: 0.5rem;
      }
      &.card-global {
        margin: 0.5rem 0rem 0rem 0rem;
        border-radius: 40px;
        border: 1px solid $ts-color-gray-35;
        background: $ts-color-white;
        padding: 4px 12px !important;
      }
    }
    &.article {
      background-color: $ts-action-small-primary;
      padding: 0.25rem;
      padding-left: 0.5rem;
      padding-right: 0.75rem;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.75rem;
      margin-left: 0.5rem;
    }
    &.dark {
      color: $ts-action-small-primary;
      background-color: $ts-action-small-secondary;
    }
    &.violet {
      color: $ts-accent-1-on-accent-1-primary;
      background-color: $ts-accent-1-primary;
    }
    &.green {
      color: $ts-accent-2-on-accent-2-primary;
      background-color: $ts-accent-2-primary;
    }
    &.blue {
      color: $ts-accent-3-on-accent-3-primary;
      background-color: $ts-accent-3-primary;
    }
    &.white {
      color: $ts-background-on-primary-1;
      background-color: $ts-background-primary;
    }
  }
}

.button.primary-button.blue {
  color: #ffffff;
  background-color: #296eeb;
}
.button {
  &.width-max {
    width: max-content;
    height: 24px !important;
  }
}

.card-body > div.tags {
  flex-wrap: wrap;
}
.button {
  &.tag {
    &.outline {
      border: 1px solid #939498;
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

button.tag > img.icon {
  width: 1rem;
  height: 1rem;
}

button {
  img {
    max-width: 1.5rem;
    max-height: 1.5rem;
    align-self: center;
  }
}
.cttagcontainer {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  position: relative;
  margin: 0.2rem 0.5rem 0.75rem 0rem;
}
.ctplaydata {
  margin-right: 0.25rem;
}
.cttagdata {
  padding: 0.125rem 0.375rem;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  .icon {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  border-radius: 0.25rem;
}
.articledata {
  background: $pale-grey;
}
.reportdata {
  background: $pale-grey;
}
.videodata {
  background: $mpg-color-blue-25;
}
.podcastdata {
  background: $color-lanselector-violet;
  color: $ts-color-white;
}
.webinardata {
  background: $form-background-green;
}
.eventdata {
  background: $form-background-green;
}
.bulletct {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.txtct {
  font-size: 0.75rem;
  font-weight: 400;
  font-family: Inter;
}
.datect {
  color: var(--tokens-container-on-container-primary-2, #67696f);
  padding: 0.35rem 0rem 0rem 0.875rem;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.75rem;
  display: inline;
  position: absolute;
  right: 0;
}
.customer-outcomes .button.tag.small {
  padding: 4px 12px 4px 8px;
  font-size: 12px;
  line-height: 12px !important;
}
.card-body.customer-outcomes-carousel {
  button {
    font-size: 16px;
  }
}
.buttonlatest {
  width: 4.5rem;
  position: relative;
  left: 1.25rem;
  top: 1.25rem;
  z-index: 10;
}
.buttonglobal {
  width: 4.5rem;
  z-index: 10;
  border: 1px solid #efefef !important;
}

.button-webinar {
  width: 88px;
  height: 36px;
  padding: 0px 0px 0px 17px !important;
  // text-align: center !important;
}
