@import './../../../../scss/colors';
@import './../../../../scss/mixins';

.breadcrumb {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
  &.on-secondary {
    li {
      a {
        @include respond-above(md) {
          color: $ts-background-on-secondary-1;
        }

        &:hover {
          border-color: $ts-background-on-secondary-1;
        }
      }
      &:after {
        background-image: url('../../../static/icons/icon--arrow-button-right--white.svg');
        color: $ts-background-on-secondary-1;
        @media screen and (max-width: 768px) {
          background-image: url('../../../static/icons/icon--arrow-button-right.svg');
        }
      }
    }
  }
  li {
    a {
      @include label(small);
      letter-spacing: initial;
      color: $ts-background-on-primary-1;
      margin: 0 0.5rem;
      line-height: 0.75rem;
      border-bottom: 0.0625rem solid transparent;
      text-decoration: none;
      &:hover {
        border-color: $ts-background-on-primary-1;
      }
    }
    &:after {
      content: '';
      position: relative;
      display: inline-block;
      background-image: url('../../../static/icons/icon--arrow-button-right.svg');
      background-size: 0.5rem;
      background-repeat: no-repeat;
      width: 0.5rem;
      height: 0.5rem;
      color: $ts-background-on-primary-1;
    }
    &:first-child,
    &:only-child {
      a {
        margin-left: 0;
      }
    }
    &:last-child {
      position: relative;
      &:after {
        display: none;
      }
      a {
        cursor: default;
        border: 0 !important;
      }
    }
  }
}
.breadcrumb {
  &.secondary {
    li {
      a {
        @include respond-above(md) {
          color: $ts-background-on-secondary-1;
        }
      }
    }
  }
}
.breadcrumb {
  &.secondary {
    li:after {
      @include respond-above(md) {
        color: $ts-background-on-secondary-1;
      }
    }
  }
}
