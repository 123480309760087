@import '../../../../scss/mixins';

.FramDataBlock_Container {
  background-color: black;
  color: white;
  padding: 80px;
  @include respond-below(xl) {
    padding: 80px;
  }
  @include respond-below(md) {
    padding: 40px 20px;
  }
  h3.rankingHeader {
    margin-bottom: 0px;
    padding-left: 0px;
    @include respond-below(md) {
      line-height: 40px;
    }
  }
  .container-xl {
    .rankingData {
      margin: 0px;
    }
    .threeRankChart,
    .donught_Container,
    .twoBarContainer {
      padding-left: 0px;
      padding-right: 0px;
      section.db-statistic {
        flex: 0 1 50%;
      }
    }
  }
}

.card-wraper {
  display: flex;
  flex-direction: row;
  gap: 12px;
  @include respond-below(md) {
    flex-direction: column;
  }
}
.block-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
  @include respond-below(lg) {
    flex-direction: column;
    align-items: center;
  }
}
.threeRankChart,
.fourCard_chart,
.donught_Container,
.twoBarContainer,
.threeBarContainer,
.twoLineContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding-top: 20px;
  @include respond-below(md) {
    flex-direction: column;
  }
}

.Headings {
  display: flex;
  gap: 16px;
  padding-top: 3.75rem;
  align-items: center;
  @include respond-below(lg) {
    padding-top: 1.25rem;
  }
  .TitleIcon {
    width: 40px;
    height: 40px;
  }
  .TitleValue2 {
    margin-left: auto;
    h3 {
      font-size: 1.75rem;
      margin-bottom: 0px;
      @include respond-below(md) {
        font-size: 1.25rem;
        line-height: 1.125rem;
      }
    }
  }
  .TitleValue1 h2 {
    margin-bottom: 0px;
    font-size: 2.25rem;
    @include respond-below(md) {
      font-size: 1.25rem;
    }
  }
}

.Description > p {
  color: white;
  padding-left: 60px;
}
.availability,
.costEfficiency,
.regulationData,
.productiveData {
  .newContainer {
    flex: 0 1 62%;
    section.data-block {
      max-width: 100%;
      width: 100%;
      height: 100%;
    }
  }

  .availabilityRanking,
  .costEfficiencyRanking,
  .regulationDataRanking,
  .productiveDataRanking {
    flex: 0 1 38%;
    .card-wraper {
      margin-top: 12px;
      height:calc((100% /3) - 8px);      
      .card_frame {
        padding: 0.5rem 1.25rem;  
        display: flex;
        align-items: center;
        .card-body {
          padding: 0px;
          h5.sub-title {
            margin-bottom: 4px;
          }
          h3.title {
            padding-bottom: 0;
          }
          h6.description {
            margin-bottom: 0;
            color: $white;
          }
          .link{
            color: $white;
          }
        }
      }
      &:is(:first-child) {
        margin-top: 0;
      }
    }
  }
}

.twoBarContainer.barchartCanvas {
  .newContainer {
    .data-block {
      .bar-chart--s {
        height: 219px;
        min-height: 219px;
        .data-block__container {
          section.bar-chart {
            canvas {
              height: 219px;
            }
          }
        }
      }
    }
  }
  .costEfficiencyRanking,
  .regulationDataRanking,
  .productiveDataRanking {
    .card-wraper {
      .card_frame {
        padding: 0.5rem 1.25rem;
        display: flex;
        align-items: center;
      }
    }
  }
}

.liner_title {
  display: flex;
  flex-direction: column;
  .linerChart_title {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px; /* 121.429% */
    letter-spacing: -0.5px;
    @include respond-below(lg) {
      font-size: 16px;
      line-height: 16px;
    }
  }
}
