@import '../../../../scss/colors';
@import '../../../../scss/mixins';
@import '../../../../scss/fonts';


.datablock_carousel.standard.insights{
  .slick-slider .slick-dots li .pagination .active{
    color: $white !important;
  }
  @include mobile{
    .slick-arrow.slick-prev{
    right: 4rem;
  }
  .slick-arrow.slick-next{
    right:1.6rem
  }
}
 
  &.secondary{
    background-color: $black;
    color:$white;
    
  }
  &.primary{
    background-color:  $black;
    color: $white;
    
  }
  .twi_bigcard {
    .insights-section{
      .featurette-section{
        .featuratte-wrapper{
          padding-left: 0px;
          padding-right: 0px;
        }
        .featurette-body{
          margin-bottom: 2.375rem;
        }
      }
    }
  .slick-track .slick-slide:first-child{
    width: 100%
     !important;
}
}
.sub-title {
  color: $color-darkgray-50;
}
padding-bottom: 0;
}
.datablock_carousel_slick.standard.insights{
  .card__full{
    &.on-secondary{
      box-shadow: 0px 7.40924px 11.5868px rgba(198, 198, 197, 0.175929), 0px 4.25707px 6.65734px rgba(198, 198, 197, 0.20529), 0px 1.87365px 2.93007px rgba(198, 198, 197, 0.27);
    &:hover{
      z-index: 20;
      box-shadow: 0 11.7703px 18.4067px rgba(0, 0, 0, 0.154142);
    }
    }
    .card-content-carousel{
      &.primary{
        background-color: unset;
      }
      &.on-secondary{
        .title{
        color: $white;
      }
      }
    }
  }
  @include respond-above(md){

    padding: 5rem;
  }
padding-top: 0px;
  padding:0;
        &.secondary{
        background-color: $black;
        .slick-slider .slick-dots li .pagination .active{
          color: $white !important;
        }
        .listenbtn {
          color: $white;
          border-bottom: 0.125rem solid $white !important;
        }
        
      }
        &.primary{
          background-color: $white;
          .slick-slider .slick-dots li .pagination .active{
            color: $ts-color-gray-75 !important;
          }
          
        }
        .section-header .title{
          font-size: 32px;
        line-height: 38px; 
        @include mobile{
        font-size: 24px;
        line-height: 28px;
        }
        }
        @include mobile{
          
          .slick-arrow.slick-prev{
          right: 4rem;
        }
        .slick-arrow.slick-next{
          right:1.6rem
        }
      }
}
.insights-section {
  &.data {
    @media screen and (max-width: 768px) {
      padding: 0px !important;
    }
    @include respond-between(md, xl) {
      padding: 0px !important;
    }
  }
}

.sub-title-carousel {
  padding-top: 0.5rem !important;
  color: $ts-background-on-secondary-2 !important;
}

.carousel {
  // background-color: $ts-background-secondary;
  // color: $white;
  // background-color: $ts-background-secondary;
  // color: $white;

  .carouselTitle {
    padding-bottom: 60px;

    
  }

  @include respond-between(md, xl) {
    // padding: 5rem 0 3rem 4rem !important
  }

  @media screen and (max-width: 768px) {
    padding: 2.5rem 1.25rem !important;
  }

  @include respond-above(xl) {
    padding: 5rem 0;
  }

  // .slick-slider {
  //     // .slick-track{
  //     //     margin-bottom: 0px !important;
  //     // }
  //     .slick-dots {
  //         li {
  //             .pagination {
  //                 // .active {
  //                 //     color: $white !important;
  //                 // }
  //             }
  //         }
  //     }
  // }
  .card__full .card-body .card-details .content-type {
    color: $white !important    ;
  }
  .card__full .card-body .card-details .date {
    color: $ts-background-on-secondary-2 !important;
  }
  .card__insights .card-body .card-details {
    .content-type {
      color: $white !important;
      @include respond-below(md) {
        color: $black !important;
      }
    }
    .date {
      color: $ts-background-on-secondary-2 !important;
    }
  }
  .card {
    .card-content-carousel .title {
      // color: $white !important;
    }
    // .card-body {
    //   width: auto !important;
    //   background-color: #282A32 !important;

    // }
  }
}

.data-block {
  &--xxs {
    max-width: 368px;
  }
}
.featurette-section {
  &.data {
    background-color: $ts-background-secondary;
    @include respond-between(md, xl) {
      padding: 0 !important;
    }
    @include respond-above(xl) {
      padding: 5rem 0 !important;
    }
    .featurette-data {
      section {
        @media screen and (max-width: 768px) {
          margin: 0 1.125rem !important;
        }
        margin: 0 auto;
      }
    }
    .featurette-body {
      padding-left: 2px;
      margin: 0 2.375rem;
      @media screen and (max-width: 768px) {
        padding-left: 1.25rem !important;
      }
    }
  }
}
