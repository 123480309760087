section {
  .footer {
    background-color: var(--#{$prefix}bgcolor);
    .ts__light {
      @include bg-mixin('light') {
        background-color: white;
      }
    }
    .ts__dark {
      @include bg-mixin('dark') {
        background-color: black;
      }
    }
  }
}
