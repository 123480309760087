@import '../../../../scss/mixins';
@import '../../../../scss/colors';
@import '../../../../scss/variables';

.navbar-section {
  padding: 0;
  color: $ts-accent-1-on-accent-1-primary;
  background-color: $ts-accent-1-primary;
  @include respond-between(md, xl) {
    padding: 1rem 2.5rem 0.75rem;
  }
  @include respond-above(xl) {
   // padding: 1rem 0;
   padding: 1rem 2.5rem 0.75rem;

  }
  .container{
    margin: 0;
  }
  .mobile-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: transparent;
    border: none;
    color: $ts-accent-1-on-accent-1-primary;
    gap: 0.5rem;
    outline: none;
    padding: 1rem 0.5rem;
    width: 100%;
    // text-decoration: underline;
    text-align: center;
    @include mobile{
      padding: 1.25rem;
    }
    &:after {
      content: '';
      display: block;
      width: 17px;
      height: 16px;
      background-size: auto;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNyAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuNjAzMjEgMTAuNzczM0w3LjcwMTcgNC45MTk5OUM3Ljc0MTI3IDQuODgxOTUgNy43ODgyNyA0Ljg1MTc3IDcuODQwMDEgNC44MzExOEM3Ljg5MTc1IDQuODEwNTkgNy45NDcyIDQuNzk5OTkgOC4wMDMyMSA0Ljc5OTk5QzguMDU5MjIgNC43OTk5OSA4LjExNDY3IDQuODEwNTkgOC4xNjY0MSA0LjgzMTE4QzguMjE4MTUgNC44NTE3NyA4LjI2NTE1IDQuODgxOTUgOC4zMDQ3MiA0LjkxOTk5TDE0LjQwMzIgMTAuNzczMyIgc3Ryb2tlPSIjRUZFRkVGIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
      margin: 0;
      transition: $btn-transition;
      transform: rotate(180deg);
    }
    &.open {
      &:after {
        transform: rotate(0deg);
      }
    }
    @include respond-above(md) {
      display: none;
    }
  }
  .navbar-wrapper {
    display: flex;
    align-items: center;
    @include respond-below(md) {
      flex-direction: column;
      color: $ts-action-primary;
      background-color: $ts-accent-1-small;
      &.close {
        display: none;
      }
    }
    .navbar-links {
      display: flex;
      flex-wrap: wrap;
      margin-right: auto;
      width: 75%;
      gap: 2.5rem;
      @include respond-below(md) {
        flex-direction: column;
        width: 100%;
        gap: 0;
      }
      .button {
        margin: 0 2rem 0 0;
        @include respond-below(md) {
          background: transparent;
          color: $ts-action-primary;
          border-bottom: 1px solid $ts-action-primary;
          border-radius: unset;
          padding: 1.25rem;
          margin: 0;
          justify-content: center;
          gap: 0.5rem;
          width: 100%;
          &:after {
            content: '';
            display: block;
            width: 15px;
            height: 12px;
            background-size: auto;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNSAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjAwMzIgNkwwLjAwMzI0OTE3IDYiIHN0cm9rZT0iYmxhY2siLz4KPHBhdGggZD0iTTkuMzM2NTUgMS4zMzMzN0wxNC4wMDMyIDYuMDAwMDFMOS4zMzY1NSAxMC42NjY3IiBzdHJva2U9ImJsYWNrIi8+Cjwvc3ZnPgo=);
            margin: 0;
            transition: $btn-transition;
          }
          &:focus {
            outline-color: $ts-action-secondary;
            border-bottom: 1px solid $ts-action-secondary;
          }
        }
        @include respond-above(md) {
          &:focus,
          &:hover {
            background-color: white;
            color: rgba(92, 75, 185, 1);
          }
          &:last-child {
            margin-right: 0;
          }
          &.active {
            background-color: white;
            color: $ts-accent-1-primary;
          }
        }
      }
    }
    .navbar-cta {
      margin-left: auto;
      width: 25%;
      @include respond-below(md) {
        background-color: $ts-action-primary;
        color: $ts-action-small-primary;
        margin: 0;
        width: 100%;
        .button {
          display: flex;
          justify-content: center;
          align-items: center;
          background: transparent;
          border-radius: unset;
          color: $ts-action-small-primary;
          padding: 1.25rem;
          width: 100%;
          gap: 0.5rem;
          &:focus {
            outline-color: $ts-action-secondary;
            border-bottom: 1px solid $ts-action-secondary;
          }
          .arrow-icon {
            position: relative !important;
            margin: 0;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMyAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC4wMDMyMzQ4NiIgd2lkdGg9IjIyIiBoZWlnaHQ9IjIyIiByeD0iMTEiIGZpbGw9IiNFRkVGRUYiLz4KPHBhdGggZD0iTTYuMDAzMjMgMTAuNUg1LjUwMzIzVjExLjVINi4wMDMyM1YxMC41Wk0xNi4wMDMyIDEwLjVMNi4wMDMyMyAxMC41VjExLjVMMTYuMDAzMiAxMS41VjEwLjVaIiBmaWxsPSJibGFjayIvPgo8cGF0aCBkPSJNMTEuMDAzMiA2TDE2LjAwMzIgMTFMMTEuMDAzMiAxNiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIvPgo8L3N2Zz4K) !important;
            right: unset !important;
            width: 2rem !important;
            height: 2rem !important;
            background-size: 2rem !important;
          }
        }
      }
    }
  }
}
