@import './../../../../scss/colors';
@import './../../../../scss/mixins';

.searchBarWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 1.875rem;

  @include respond-below(md) {
    display: flex;
    flex-direction: column;
  }

  .searchBarInputWrapper {
    @include respond-above(lg) {
      position: absolute;
      top: 0;
      bottom: 0;
      // left: calc(100% - 19rem);
      //left: calc(100% - 22rem);
      display: flex;
      align-items: center;
      width: 0;
      background-color: $ts-color-black;
      transition: width 0.3s ease-out, left 0.3s ease-out, background-color 0s ease-out;

      &.expanded {
        left: 17rem;
        // width: calc(100% - 17rem);
        width: calc(100% - 19.5rem);
        transition: width 0.3s ease-out, left 0.3s ease-out, background-color 0s ease-out;
      }
    }

    @include respond-below(lg) {
      position: absolute;
      top: 0;
      left: calc(100% - 16rem);
      bottom: 0;
      display: flex;
      align-items: center;
      width: 0;
      background-color: $ts-color-black;
      transition: width 0.3s ease-out, left 0.3s ease-out, background-color 0s ease-out;

      &.expanded {
        left: 16rem;
        width: calc(100% - 17.25rem);
        transition: width 0.3s ease-out, left 0.3s ease-out, background-color 0s ease-out;
      }
    }
    // @include respond-below(md) {
    @media screen and (max-width: 992px) {
      left: calc(100% - 6.25rem);
      &.expanded {
        left: 14rem;
        width: calc(100% - 14rem);
      }
    }

    @include respond-below(md) {
      &.expanded {
        left: 14rem;
        width: calc(100% - 14rem);
      }
    }

    @include respond-below(sm) {
      left: calc(100% - 7.5rem);
      &.expanded {
        left: 0;
        width: 100%;
      }
    }
  }

  .searchBarInput {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    width: 0;
    height: 2.5rem;
    padding: 0;
    border: none;
    transition: width 0.3s ease-out, padding-left 0.3s ease-out, clip-path 0.3s ease-out,
      background-color 0s ease-out;
    overflow: hidden;

    &.expanded {
      width: 100%;
      padding-left: 2.813rem;
      transition: width 0.3s ease-out, padding-left 0.3s ease-out, clip-path 0.3s ease-out,
        background-color 0s ease-out;
      overflow: visible;
    }

    input[type='text'] {
      @include headline(5);
      @include placeholder-text($ts-action-on-disabled-secondary);
      width: calc(100% - 2.5rem);
      margin-right: 2.813rem;
      padding: 0;
      border: none;
      border-bottom: 0.0625rem solid $ts-action-outline-secondary;
      border-radius: 0;
      background-color: transparent;
      font-size: 1.5rem;
      font-weight: 400;
      color: $ts-color-white;
      text-transform: none;
      line-height: 1.875rem;
      letter-spacing: -0.031rem;
      transition: all 0.3s ease-out;

      @include respond-below(md) {
        margin-right: 4.75rem;
      }

      @include respond-below(sm) {
        width: 100%;
        margin-right: unset;
      }

      @media screen and (max-width: 20rem) {
        font-size: 1.25rem;
        line-height: 1.5rem;
      }

      &:focus,
      &:focus-visible,
      &:focus-within,
      &:active {
        outline: none;
      }
      &.typing {
        color: $ts-color-white;
      }
    }

    &.close_visible {
      input[type='text'] {
        margin-right: 1.25rem;
        @include respond-below(sm) {
          margin-left: 1.25rem;
        }
      }
    }
    // Override the default search button
    .search_btn {
      display: none;
    }

    .searchFieldWrapper {
      flex: 1;
    }

    @include respond-below(sm) {
      z-index: 1;
      display: flex;
      flex-direction: column;
      opacity: 0;
      &.expanded {
        top: 0;
        padding-top: 5.625rem;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        opacity: 1;
      }
    }
  }

  .searchBarButton {
    position: relative;
    z-index: 12;
    border: none;
    background-color: transparent;
    transition-duration: 0s;
    @include respond-below(sm) {
      z-index: 10;
      left: 1.25rem;
    }
  }

  .searchBarBtnClose {
    width: 2.5rem;
    height: 2.5rem;
    border: 0;
    border-radius: 50%;
    background-color: $ts-action-small-secondary;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.3643 17.3645L1.36426 1.3645' stroke='white' stroke-width='2'/%3E%3Cpath d='M1.36353 17.3645L17.3635 1.3645' stroke='white' stroke-width='2'/%3E%3C/svg%3E");
    background-position: center;
    background-size: 1.188rem auto;
    background-repeat: no-repeat;
    font-size: 0;
    z-index: 10;

    @include respond-above(md) {
      &.mobile {
        display: none;
      }
      &.desktop {
        display: block;
      }
    }
    @include respond-below(md) {
      &.mobile {
        position: absolute;
        display: block;
        right: 1.25rem;
      }
      &.desktop {
        display: none;
      }
    }
  }

  .searchBarBtnSearch {
    margin-right: 1.25rem;
    padding: 0.375rem 1rem;
    border: none;
    border-radius: 2.5rem;
    outline: none;
    background-color: $ts-color-white;
    color: $ts-color-black;

    @include respond-below(md) {
      margin-right: 4.75rem;
    }

    @include respond-below(md) {
      margin-right: 3.5rem;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover,
      &:focus,
      &:focus-visible,
      &:focus-within,
      &:active {
        outline: none;
        background-color: $ts-color-violet-100;
        color: $ts-color-white;
      }
    }

    @include respond-below(sm) {
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
      margin-left: auto;
      margin-right: 0;
    }
  }

  &.white__background {
    .searchBarBtnClose {
      background-color: $ts-action-small-primary;
      background-image: url("data:image/svg+xml,%0A%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.3643 17.3645L1.36426 1.3645' stroke='black' stroke-width='2'/%3E%3Cpath d='M1.36353 17.3645L17.3635 1.3645' stroke='black' stroke-width='2'/%3E%3C/svg%3E");
    }

    .searchBarBtnSearch {
      background-color: $ts-color-black;
      color: $ts-color-white;
      &:hover,
      &:focus,
      &:focus-visible,
      &:focus-within,
      &:active {
        outline: none;
        background-color: $ts-color-violet-100;
        color: $ts-color-white;
      }
    }

    input[type='text'] {
      color: $ts-color-black;
      &.typing {
        color: $ts-color-black;
      }
    }

    .searchBarInputWrapper {
      background-color: $ts-color-white;
      transition: background-color 0s linear;
    }
  }
}

.header {
  .searchBarButton {
    background-image: url("data:image/svg+xml,%3Csvg width='27' height='27' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.4294 19.8589C15.6372 19.8589 19.8589 15.6372 19.8589 10.4294C19.8589 5.22171 15.6372 1 10.4294 1C5.22171 1 1 5.22171 1 10.4294C1 15.6372 5.22171 19.8589 10.4294 19.8589Z' stroke='white' stroke-width='2' stroke-linecap='square' stroke-linejoin='round'/%3E%3Cpath d='M25.0038 25.0001L18.146 18.1423' stroke='white' stroke-width='2' stroke-linecap='square' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-position: center;
    background-size: 24px;
    background-repeat: no-repeat;
    font-size: 0;
    color: transparent;
    width: 24px;
    max-width: 24px;
    min-width: 24px;
    @include aspect-ratio(1, 1);

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-image: url("data:image/svg+xml,%3Csvg width='27' height='27' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.4294 19.8589C15.6372 19.8589 19.8589 15.6372 19.8589 10.4294C19.8589 5.22171 15.6372 1 10.4294 1C5.22171 1 1 5.22171 1 10.4294C1 15.6372 5.22171 19.8589 10.4294 19.8589Z' stroke='%235C4BB9' stroke-width='2' stroke-linecap='square' stroke-linejoin='round'/%3E%3Cpath d='M25.0038 25.0001L18.146 18.1423' stroke='%235C4BB9' stroke-width='2' stroke-linecap='square' stroke-linejoin='round'/%3E%3C/svg%3E");
      }
    }
  }

  &.white__background {
    .searchBarButton {
      background-image: url("data:image/svg+xml,%3Csvg width='27' height='27' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.4294 19.8589C15.6372 19.8589 19.8589 15.6372 19.8589 10.4294C19.8589 5.22171 15.6372 1 10.4294 1C5.22171 1 1 5.22171 1 10.4294C1 15.6372 5.22171 19.8589 10.4294 19.8589Z' stroke='black' stroke-width='2' stroke-linecap='square' stroke-linejoin='round'/%3E%3Cpath d='M25.0038 25.0001L18.146 18.1423' stroke='black' stroke-width='2' stroke-linecap='square' stroke-linejoin='round'/%3E%3C/svg%3E");

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-image: url("data:image/svg+xml,%3Csvg width='27' height='27' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.4294 19.8589C15.6372 19.8589 19.8589 15.6372 19.8589 10.4294C19.8589 5.22171 15.6372 1 10.4294 1C5.22171 1 1 5.22171 1 10.4294C1 15.6372 5.22171 19.8589 10.4294 19.8589Z' stroke='%235C4BB9' stroke-width='2' stroke-linecap='square' stroke-linejoin='round'/%3E%3Cpath d='M25.0038 25.0001L18.146 18.1423' stroke='%235C4BB9' stroke-width='2' stroke-linecap='square' stroke-linejoin='round'/%3E%3C/svg%3E");
        }
      }
    }
  }
}
