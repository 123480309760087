@import '../../../scss/mixins';
@import '../../../scss/colors';
$link-transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);

.country-selector-section {
  @include mobile {
    padding: 0px !important;
    margin: 60px 20px;
  }
  padding: 5rem 11.75rem;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
}
.country-search {
  // width: 300px;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.modal.show .fullscreen {
  width: 100%;
  max-width: none;
  height: 100%;
  margin: 0;
  min-height: 600px;
  @include mobile {
    padding-left: 0px !important;
  }
  .modal-content {
    height: 100%;
    border: 0;
    border-radius: unset !important;
    top: 0;
    padding-top: 96px;
    @include desktop {
      padding-top: 96px;
    }
    @include tablet {
      padding-top: 65px;
    }
    @include mobile {
      padding-top: 65px;
    }
    .modal-header {
      padding-bottom: 0;
      .container {
        .closeDiv {
          float: right;
          text-transform: uppercase;
          font-size: 1.125rem;
          cursor: pointer;
        }
        .closeText {
          margin-right: 0.5rem;
        }
      }
      .btn-close {
        display: none !important;
      }
    }
  }

  .modal-body {
    // padding-top: 0;
    overflow-y: auto;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .country-links {
    padding: 7px;
  }
}
