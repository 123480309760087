@import './../../../../scss/variables';
@import './../../../../scss/colors';
@import './../../../../scss/mixins';
.video-carousel {
  .carousel-wrapper {
    .slick-track .slick-slide:first-child {
      width: 100% !important;
    }
    .slick-slider {
      .slick-dots {
        bottom: initial;
      }
      .slick-track {
        p.card-text {
          padding: 28px;
          margin-top: 0;
          font-size: 20px;
          line-height: 24px;
          @include respond-below(md) {
            padding: 12px;
            margin-top: 0;
            font-size: 16px;
            line-height: 20px;
          }
        }
        .slick-slide {
          min-width: unset;
          max-width: unset;
          width: 100% !important;
          :first-child {
            width: 100% !important;
            border-radius: 0.5rem;
          }
          border-radius: 0.5rem;
          box-shadow: 0px 1.874px 2.93px 0px rgba(198, 198, 197, 0.27),
            0px 4.257px 6.657px 0px rgba(198, 198, 197, 0.21),
            0px 7.409px 11.587px 0px rgba(198, 198, 197, 0.18),
            0px 11.77px 18.407px 0px rgba(198, 198, 197, 0.15),
            0px 18.161px 28.4px 0px rgba(198, 198, 197, 0.14);
          a {
            position: relative;
            border-radius: 0;
            box-shadow: unset;
            .videoIcon {
              position: absolute;
              color: $black;
              left: 38%;
              top: 30%;
              opacity: 1;
              transition: 0.3s ease;
              background-color: transparent;
              height: 44% !important;
              width: 26% !important;
              @include respond-below(md) {
                height: 38% !important;
                width: 28% !important;
                left: 36%;
              }
            }
            .card-body-image-carousel,
            img:not(.videoIcon) {
              width: 100% !important;
              height: 13.75rem !important;
              object-fit: cover;
            }
            @include respond-below(md) {
              height: 170px !important;
              width: 253px !important;
            }
          }
          :has(.violet) {
            background-color: $color-lanselector-violet;
            .card-text {
              color: $white;
            }
          }
          :has(.black) {
            background-color: $color-darkgray-100;
            .card-text {
              color: $color-darkgray-50;
            }
          }
        }
      }
    }
  }
  .section-header {
    @include respond-below(md){
        padding-left: 15px;    
    }
    padding-left: 30px;
    .title {
      margin-bottom: 12px;
    }
    p.description {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
.videoCarousel_modal {
  display: none;

  &.is-active {
    position: fixed;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
  }

  &__background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
  }

  &__content {
    @include respond-below(md) {
      right: 1rem;
    }
    position: absolute;
    z-index: 101;
    max-width: 66.5rem;
    width: 90%;
    margin: 0 auto;
    top: 7rem;
    right: 11rem;
    border-radius: $ts-card-border-radius;
    background-color: $ts-color-white;
    box-shadow: $ts-card-box-shadow-light;
    overflow: hidden;
  }

  &__inner {
    position: relative;
    padding: 3.75rem 3.75rem 2.5rem 3.75rem;
    max-height: 100vh;
    overflow-y: auto;
   @media screen and (max-width: 350px) {
      padding: 2rem 1rem;
    }
  }

  &__close {
    @include respond-below(md) {
      top: 0;
    }
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;

    &--btn {
      width: 1rem;
      height: 1rem;
    }
  }
  &__title{
    position: absolute;
    top: 1rem;
    left: 4rem;
    background: none;
    border: none;
    
  }
  &__body {
    height: 25rem;
  }
}
.carousel.standard.video-carousel .header-wrapper {
  @include respond-below(md) {
    margin-bottom: 2rem;
  }
}
