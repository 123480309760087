@import './../../../scss/mixins';
@import './../../../scss/colors';
@import '../../../globals/typography/typography.scss';

// Talent Solutions Theme Site Footer Component Overrides
.site-footer {
  .title {
    color: black !important;
    font-weight: 600 !important;
  }
  .footer-nav {
    a {
      color: #67696f !important;
    }
  }
  .footer-newsletter {
    @include tablet {
      border-top: 1px solid #c9cacc;
      padding-top: 2.5rem;
      margin: 0;
    }
    p {
      color: #cc334d;
      margin-top: 0.75rem;
    }
  }
  .footer-disclaimer {
    background-color: black;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333% !important;
  }
  .footer-bottom {
    margin: 0 2.938rem 0 3.313rem;
    padding-top: 3.75rem;
    padding-bottom: 2rem;
  }
}

.footer-bottom-links {
  background-color: #000;
  // padding-bottom: 4.625rem;
  @include respond-below(md) {
    padding-bottom: 2rem;
  }
}
.social-bar,
.lang-selector {
  margin: 1rem 0;
  text-align: center;
  margin: 0;
}
.social-bar {
  padding-top: 0rem;
  margin-top: 0;
}
.copyright p {
  color: #fff;
  font-size: 0.75rem;
  line-height: 2;
  margin: 0;
}
.footer-lang-selector {
  text-align: right;
}
.footer-lang-selector button {
  background: transparent;
  border: none;
  color: #fff;
}
.bdr-top {
  padding: 0;
  border-top: 1px solid #c9cacc;
  margin: 0 1.25rem;
  @include respond-above(lg) {
    margin: 0 4.0625rem;
  }
}
.footer-bottom {
  padding-top: 3rem;
}
.footer-disclaimer-links a:first-child {
  margin-left: 0;
}
.footer-disclaimer-links button.primary-button{
  padding: 0;
}
.footer-disclaimer-links a:last-child:after {
  content: '';
}
.footer-disclaimer-links a,.footer-disclaimer-links button {
  position: relative;
  display: inline-block;
  margin-left: 0.625rem;
  font-size: 0.75rem;
  text-transform: capitalize;
  color: #fff !important;
  font-weight: 400;
  letter-spacing: normal;
  text-decoration: none;
  line-height: 1.125rem;
  text-align: center;
}
.footer-disclaimer-links a:after,.footer-disclaimer-links button:after {
  content: '|';
  display: inline-block;
  margin-left: 0.625rem;
}
@media (max-width: 991px) {
  .footer-bottom-links .copyright {
    order: 3;
    text-align: center;
    margin-top: 0;
  }
  .footer-bottom-links .copyright p {
    margin: 0;
  }
  .footer-bottom-links .footer-lang-selector {
    text-align: center;
    margin: 0;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    order: 1;
  }
  .social-follow {
    display: flex;
    &.justify-center {
      justify-content: center; //donot change
    }
    // justify-content: center;
    order: 2;
    margin-bottom: 2rem;
  }
  .social-bar,
  .lang-selector {
    margin: 0;
  }
}
.footer-newsletter {
  margin-bottom: 3rem;
  @include respond-above(md) {
    padding-left: 3.75rem;
  }
}

.pretext > div {
  font-size: 1rem;
  line-height: 1.125rem;
  margin: 1.5rem 0;
}

.newsletter-form-disclaimer .message .posttext span,
.newsletter-form-disclaimer .message .posttext a {
  color: #67696f;
  font-size: 0.75rem;
  line-height: 1.66;
}
.posttext {
  margin-bottom: 1rem;
}
.footer-disclaimer-icons {
  margin-bottom: 1.25rem;
  @include respond-below(xl) {
    margin-bottom: 0.5rem;
  }
}
.footer-disclaimer-icons a img {
  margin: 0 1.28125rem;
  margin-bottom: 1.5rem;
  @media (max-width: 576px) {
    width: 40px;
    height: 40px;
  }
  @media (min-width: 577px) {
    width: 50px;
    height: 50px;
  }
  @media (min-width: 991px) {
    width: 80px;
    height: 80px;
  }
}

.newsLetter_right {
  float: left;
}
.newsLetter_left {
  float: left;
}

@media (max-width: 576px) {
  .footer-disclaimer-icons a img {
    margin: 0 15px;
    margin-bottom: 2rem;
  }
}

.error-message {
  color: $form-error-color !important;
  font-size: 12px !important;
}

a:hover {
  text-decoration: underline;
}

.social-follow {
  .social-bar {
    .social-icon:hover {
      opacity: 0.75;
    }
  }
}

.section-1,
.section-2 {
  width: 100%;
}

.section-contact-form {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.newsletter-noleft {
  @include respond-above(md) {
    padding-left: 0;
  }
}
.site-footer .footer-rightside.newsletter-noleft {
  @include respond-above(md) {
    padding-left: 0;
  }
}
.contact-checkbox-label > div {
  line-height: 1.125rem;
  font-size: 12px;
  font-weight: 400;
}
.sub-footer-apps {
  @include respond-above(md){
   justify-content: right;
  }
  @include respond-below(md){
    justify-content: center;
  }
  display: flex;
  align-items: center;
  background-color: $black;
  margin-top: -1px;
  padding-bottom: 4.625rem;
  .applinks {
    margin: 0 2.938rem 0 3.313rem;
    .applink-one {
      margin-right: 20px;
      @include mobile {
        margin-right: 0px;
      }
    }
    .applink-two {
      @include mobile {
        margin-left: 0px;
      }
    }
    .apps-tablet {
      @include tablet {
        justify-content: center;
      }
    }
    .trust-seal {
      padding-right: 9px;
      @include tablet {
        justify-content: center !important;
        margin-top: 0px;
        padding-right: 15px;
      }
    }
  }
}

