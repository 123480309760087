@import './../../../../scss/colors';
@import './../../../../scss/mixins';
@import '../../../globals/typography/typography.scss';

.card-wrapper {
  &.callout {
    @include respond-above(md) {
      min-height: 350px;
      align-items: center;
      .card {
        height: 300px;
        align-items: center;
        transition: $btn-transition;
        box-shadow: $ts-card-box-shadow-light;
        .card-header .icon {
          height: 60px;
          width: 60px;
        }
        .card-body {
          .title,
          p,
          .button {
            transition: $btn-transition;
          }
          p {
            max-height: 3rem;
          }
          h3.title-solution {
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 34px; /* 121.429% */
            letter-spacing: -0.5px;
          }
        }
        &:hover {
          height: 350px;
          box-shadow: $ts-card-box-shadow-light-hover;

          .card-body {
            padding: 2rem 1.25rem 1.25rem 1.25rem;
            p {
              color: $ts-container-on-container-primary-1;
              max-height: 8rem;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 5;
              -webkit-box-orient: vertical;
            }
            .button {
              opacity: 1;

              &:before {
                background-color: $ts-accent-1-primary;
                height: 0.25rem;
                bottom: -0.25rem;
              }
            }
          }
        }
      }
      &__service {
        min-height: 235px;
        .card {
          height: 190px;
          &:hover {
            height: 272px;
            @include respond-above(xl) {
              height: 272px;
              p {
                -webkit-line-clamp: 3;
                max-height: 4rem;
              }
            }
          }
        }
      }
    }
    @include respond-above(md) {
      margin: 0;
      display: flex;
      flex-basis: 50%;
    }
    @include respond-above(lg) {
      flex-basis: 25%;
      // &:hover {
      //     --transform-scale-x: 1.1;
      //     --transform-scale-y: 1.1;
      // }
    }
    &.active {
      @include tablet {
        .card-header {
          align-items: flex-start;
          .icon {
            height: 3.5rem;
            width: 3.5rem;
            max-height: 3.5rem;
            max-width: 3.5rem;
            margin-bottom: 3.25rem;
          }
          .title {
            margin: 0;
            visibility: hidden;
            &:after {
              visibility: visible;
              background-image: url('../../../static/images/icon--collapse.svg');
            }
          }
        }
        .card-content {
          display: block;
          p {
            color: $ts-container-on-container-primary-1;
          }
          button {
            margin-top: 1.25rem;
            opacity: 1;
          }
        }
      }
    }

    &__service {
      // &:hover {
      //     --transform-scale-x: 1.05;
      //     --transform-scale-y: 1.05;
      // }
      @include respond-above(md) {
        flex-basis: 33%;
        .card-header {
          margin: 0 0 1rem 0;
          .title {
            display: block;
            padding: 0;
            &:after {
              display: none;
            }
          }
          .icon {
            max-height: 2rem;
            max-width: 2rem;
            margin: 0 1.25rem 0 0;
          }
        }
        .card-content {
          .title {
            display: none;
          }
        }
      }
    }
  }
}
.card-wrapper.callout__service .card-header.m-0 {
  margin: 0;
  padding: 0;
}
.card-wrapper.callout .card .card-body p {
  margin-bottom: 24px;
  @include mobile {
    margin-bottom: 20px;
  }
}
