@import './../../../scss/colors';
@import './../../../scss/mixins';

.search_page {
  isolation: isolate;

  > .container {
    width: 100%;
    padding: 1.25rem;
    max-width: 57.813rem;
  }
}

.search_header {
  position: relative;
  z-index: 10;
  .site-search-filter-content {
    padding: 0;
    .filter-content {
      padding: 0;
      .container-xl {
        padding: 0;
      }
    }
  }
  &__showing_results {
    p {
      padding: 0;
      font-size: 1rem;
      color: $ts-color-gray-75;

      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      // line-height: 16px

      span {
        font-size: inherit;
        font-weight: 400;
        color: $ts-color-black;
      }
    }
  }

  .results_shown {
    input {
      color: $ts-color-violet-75;
      padding: 0;
    }
  }

  &__top {
    position: relative;
    z-index: 30;

    &--left {
      flex: 1;
      @include respond-above(lg) {
        max-width: 77%;
      }
    }

    &--right {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @include respond-above(lg) {
        max-width: 23%;
      }

      &.results_shown {
        &.mobile {
          justify-content: space-between;
          margin-top: 2.5rem;
          margin-bottom: 2.5rem;
        }
      }
    }

    &--filterbtn {
      .filter_btn {
        background-color: $ts-action-secondary;
        border: 0.0625rem solid $ts-color-gray-50;
        border-radius: 2.5rem;
        color: $ts-action-on-action-secondary;
        letter-spacing: 0;
        line-height: 1;
        z-index: auto;
        outline: none;

        @include mobile {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.25;
          letter-spacing: -0.02em;
          padding: 0.4rem 0.8rem;
        }

        &:hover {
          border: 0.0625rem solid $ts-outline-on-primary-border-hover;
        }

        &:focus,
        &:focus:not(:focus-visible) {
          outline: none;
          box-shadow: 0 0 0 0.0625rem $ts-color-gray-50 inset;
        }
      }
      &.active {
        .filter_btn {
          outline: none;
          box-shadow: 0 0 0 0.0625rem $ts-color-violet-100 inset;
          &:active,
          &:focus,
          &:focus:not(:focus-visible) {
            outline: none;
            box-shadow: 0 0 0 0.0625rem $ts-color-violet-100 inset;
          }
        }
      }
    }
  }

  &__bottom {
    margin-top: 2.5rem;
    margin-bottom: 3.75rem;

    @include respond-above(md) {
      display: block;
    }

    @include respond-below(md) {
      display: none;
      margin-top: 0;
    }

    &.show {
      display: block;
    }
  }
}

.search_results {
  position: relative;
  z-index: 5;

  &.results__container {
    padding-bottom: 1.25rem;
  }
}

@media (min-width: 440px) {
  .filter-content-search {
    min-width: 21rem;
  }
}
@media (min-width: 992px) {
  .filter-content-search {
    min-width: 80rem;
  }
}
.search_btn {
  border: 0;
  background-color: $ts-color-white;
}

.sort_order {
  display: flex;
  align-self: center;
  justify-content: start;
  min-width: 10rem;
  color: $ts-color-black;

  label {
    display: flex;
    padding: 0;
    margin: 0;
    font-size: 1rem;
    color: inherit;
    align-items: center;

    @media screen and (max-width: 320px) {
      font-size: 0.875rem;
    }
  }

  select {
    padding: 0;
    border: 0;
    text-transform: lowercase;
    margin-left: 0.5rem;
    margin-right: 0;
    font-size: 1rem;
    text-align: left;
    cursor: pointer;
    color: inherit;

    @include mobile {
      border-radius: 0.906em;
      height: 1.813rem;
      white-space: pre;
    }

    @media screen and (max-width: 320px) {
      font-size: 0.875rem;
    }

    &:focus {
      outline: none;
    }

    option {
      color: inherit;
      text-transform: capitalize;
    }
  }
}

.pagination_section {
  margin-top: 2.5rem;
}
.col-lg-9 {
  display: flex;
  width: 55.375rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.wrapper_3 {
  flex-wrap: nowrap !important;
}

.search_header .results_shown input {
  font-family: Inter;
  font-size: 2.25rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 2.625rem !important;
  letter-spacing: -1px !important;
}
