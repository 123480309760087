$mobile-width-small: 375px;
$mobile-width: 767px;
$tablet-width: 991px;
$desktop-large-width: 1400px;

$btn-transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
$link-transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);

$ui-image-border-radius: 0.875rem;
$ts-card-border-radius: 0.5rem;

$font-family-1: 'Inter', Helvetica, Arial, 'Droid Sans', 'Arimo', sans-serif;
$font-heading: 'Inter', Helvetica, Arial, 'Droid Sans', 'Arimo', sans-serif;
$font-inter: 'Inter';
$font-arial: 'Arial';
$font-arial-2: 'Arial', 'Droid Sans';

$form-error-color: #cc334d;
$checkbox-font-family: 'Montserrat';
$form-error-box-border-color: #fc5c7d;
$form-error-box-border-shadow-color: rgba(228, 65, 95, 0.12);

$form-background-primary: #5c4bb9;
$form-background-green: #a6eb8c;
$form-background-blue: #56bded;
$form-background-secondary: #000000;
$form-white-color: #fff;

$contact-button-border-color: #939498;
$contact-button-hover-bgcolor: #4f4296;
$contact-mobile-text-color: #67696f;

$font-copy: Helvetica, Arial, 'Droid Sans', 'Arimo', sans-serif;
$font-family-2: Helvetica, Arial, 'Droid Sans', 'Arimo', sans-serif;

/* TALENT SOLUTIONS CARD STYLES */

$ts-card-box-shadow-light: 0px 7.40924px 11.5868px rgba(198, 198, 197, 0.175929),
  0px 4.25707px 6.65734px rgba(198, 198, 197, 0.20529),
  0px 1.87365px 2.93007px rgba(198, 198, 197, 0.27);
$ts-card-box-shadow-light-hover: 0 11.7703px 18.4067px rgba(0, 0, 0, 0.154142);

$ts-tab-box-shadow-light-active: 0 11.7703px 18.4067px rgba(0, 0, 0, 0.154142);

$ts-card-box-shadow-dark: 0px 94px 147px rgba(198, 198, 197, 0.0647102),
  0px 47.0459px 73.5718px rgba(198, 198, 197, 0.094071),
  0px 28.3382px 44.3162px rgba(198, 198, 197, 0.115858),
  0px 18.1606px 28.4001px rgba(198, 198, 197, 0.135),
  0px 11.7703px 18.4067px rgba(198, 198, 197, 0.154142),
  0px 7.40924px 11.5868px rgba(198, 198, 197, 0.175929),
  0px 4.25707px 6.65734px rgba(198, 198, 197, 0.20529),
  0px 1.87365px 2.93007px rgba(198, 198, 197, 0.27);
