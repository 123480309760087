@import './../../../scss/colors';
@import './../../../scss/mixins';

// remove the color and underline from the card link
a.search_result_card_link {
  color: unset;
  text-decoration: none;
  &:hover {
    color: unset;
    text-decoration: none;
  }
}

.search_results_card {
  background-color: $ts-color-violet-25;
  border: 1px solid $ts-color-violet-50;
  max-width: unset;
  min-height: 130px;
  padding: 35px 45px;
  background: $ts-color-white;
  border-color: $ts-color-gray-25;
  border-width: 1px 0 0;
  border-style: solid;
  border-radius: 0;
  position: relative;
  z-index: 19;

  &:last-child {
    border-width: 1px 0;
  }

  @include respond-below(md) {
    padding: 20px 12px;
  }

  @include respond-above(md) {
    &:hover {
      z-index: 20;
      border-radius: 12px;
      box-shadow: 0px 28px 40px rgba(198, 198, 197, 0.115858),
        0px 18px 28px rgba(198, 198, 197, 0.135),
        0px 12px 18px rgba(198, 198, 197, 0.154142),
        0px 7px 11px rgba(198, 198, 197, 0.175929),
        0px 4px 6px rgba(198, 198, 197, 0.20529),
        0px 2px 3px rgba(198, 198, 197, 0.27);
    }
  }

  &.solution {
    border-radius: 0;
    box-shadow: none;
    @include respond-above(md) {
      &:hover {
        z-index: 20;
        border-radius: 12px;
        box-shadow: 0px 28px 40px rgba(198, 198, 197, 0.115858),
          0px 18px 28px rgba(198, 198, 197, 0.135),
          0px 12px 18px rgba(198, 198, 197, 0.154142),
          0px 7px 11px rgba(198, 198, 197, 0.175929),
          0px 4px 6px rgba(198, 198, 197, 0.20529),
          0px 2px 3px rgba(198, 198, 197, 0.27);
      }
    }
    .card-body {
      padding: 0;
    }
  }
}

.search_results_card {
  .card-details {
    display: flex;
    align-items: center;
    .tags {
      @include label(small);
      color: $ts-background-on-primary-1;
      display: inline-flex;
      align-items: center;
      margin-right: auto;
      .bullet {
        margin: 0 0.5rem;
      }
      &__2 {
        .tag {
          margin-right: 0.25rem;
        }
        .bullet {
          margin: 0 0.25rem 0 0;
        }
      }
    }
    .date {
      @include label(small);
      color: $ts-background-on-primary-2;
      margin-left: 0.75rem;
    }
    .content-type {
      text-transform: capitalize;
    }
  }

  .card-body {
    .pre_title {
      margin: 1rem 0 0;
      @include label(medium);
      color: $ts-background-on-primary-2;
    }
    .title {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2; 
      -webkit-box-orient: vertical;
      @include headline(5);
      margin: 1rem 0 0.75rem;
      letter-spacing: -0.5px;
      color: $ts-color-black;
      @include respond-below(md) {
        font-size: 1.5rem;
      }
    }

    p {
      color: $ts-background-on-primary-2;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      &:first-of-type {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .job_information {
    display: flex;
    align-items: center;
    gap: 40px;
    justify-content: flex-start;
    color: $ts-color-black;
    font-size: 1.25rem;
    margin-bottom: 0.75rem;

    @include respond-below(md) {
      font-size: 1rem;
    }

    .location {
      font-size: inherit;
      display: flex;
      gap: 10px;
      align-items: center;
      img {
        width: 15.56px;
        height: 15.56px;
      }
    }
    .jobtype,
    .event_time {
      font-size: inherit;
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
}

.search_results_card {
  .button.tag {
    padding: 4px 6px;
    border-radius: 4px;
    .icon {
      margin-left: 0;
      margin-right: 4px;
    }
  }

  .button.tag.outline {
    background-color: transparent;
  }

  &.solution {
    .title {
      margin: 1.375rem 0 1.125rem;
    }
    .button.tag.outline {
      border-color: $ts-color-black;
      color: $ts-color-black;
    }
  }
}

.search_results_card {
  .descBullets {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 20px;
    max-width: 90%;

    li {
      margin-right: 2em;
      color: $ts-container-on-container-primary-2;
      &::marker {
        color: $ts-color-black;
      }

      @include respond-below(md) {
        font-size: 12px;
      }
    }

    &.searches {
      list-style: none;
      padding-left: 0px;
      max-width: 100%;
      gap: 16px;
      li {
        margin-right: 0;
        border: 1px solid $ts-color-gray-50;
        border-radius: 20px;
        font-size: 16px;
        border-radius: 40px;
        padding: 6px 16px;
        color: $ts-color-black;
      }
    }
  }

  .searchBullets {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 0;
    padding-left: 20px;
    max-width: 90%;

    list-style: none;
    padding-left: 0px;
    max-width: 100%;
    gap: 16px;
    li {
      margin-right: 0;
      border: 1px solid $ts-color-gray-50;
      border-radius: 20px;
      font-size: 16px;
      border-radius: 40px;
      padding: 6px 16px;
      color: $ts-color-black;
    }
  }
}
