@import './../../../../scss/variables';
@import './../../../../scss/colors';
@import './../../../../scss/mixins';

.language_subnav {
  &__footer {
    margin-top: 2rem;
  }
}
// Language CTA Styling
.language__cta {
  isolation: isolate;
  z-index: 1;

  .language_button_no_languages {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.625rem;
    height: 2rem;
    padding: 0.5rem 0.75rem;
    border: 0.0625rem solid $ts-color-gray-50;
    border-radius: 1.25rem;
    background-color: transparent;
    color: $ts-color-white;

    img {
      width: 0.938rem;
    }
  }

  .language_button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.625rem;
    height: 2rem;
    padding: 0.5rem 0.75rem;
    border: 0.0625rem solid $ts-color-gray-50;
    border-radius: 1.25rem;
    background-color: transparent;
    color: $ts-color-white;

    img {
      width: 0.938rem;
    }

    &:after {
      position: relative;
      width: 0.75rem;
      height: 0.5rem;
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.0001 1L7.00006 7L1.00006 0.999999' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      content: '';
    }

    &.open {
      &:after {
        background-image: url("data:image/svg+xml,%0A%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.00012 8L7.00012 2L13.0001 8' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");
      }
    }
  }

  &.white__background {
    .language_button {
      color: $ts-color-black;
      &:after {
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.0001 1L7.00006 7L1.00006 0.999999' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
      }
      &.open {
        &:after {
          background-image: url("data:image/svg+xml,%0A%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.00012 8L7.00012 2L13.0001 8' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
        }
      }
    }
    .language_button_no_languages {
      color: $ts-color-black;
    }
  }
}

// Language Modal Styling
.language_modal {
  display: none;

  &.is-active {
    position: fixed;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
  }

  &__background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
  }

  &__content {
    position: absolute;
    z-index: 101;
    max-width: 37.5rem;
    width: 90%;
    margin: 0 auto;
    top: 6rem;
    right: 2rem;
    border-radius: $ts-card-border-radius;
    background-color: $ts-color-white;
    box-shadow: $ts-card-box-shadow-light;
    overflow: hidden;
  }

  &__inner {
    position: relative;
    padding: 3.75rem 3.75rem 2.5rem 3.75rem;
    max-height: 100vh;
    overflow-y: auto;

    @include respond-below(sm) {
      padding: 2rem 1.75rem;
    }

    @media screen and (max-width: 350px) {
      padding: 2rem 1rem;
    }

    .title {
      @include headline(medium);
      width: 90%;
      margin: 0;
      letter-spacing: -0.031rem;

      @include respond-below(sm) {
        width: auto;
        font-size: 1.75rem;
      }

      @media screen and (max-width: 350px) {
        font-size: 1.5rem;
      }
    }

    .subtitle {
      @include body-copy(medium);
      width: 90%;
      margin-top: 0.75rem;
      color: $ts-container-on-container-primary-2;
      @include respond-below(sm) {
        width: auto;
        font-size: 1rem;
      }

      @media screen and (max-width: 350px) {
        font-size: 1rem;
      }
    }

    .select .title {
      @include label('medium-bold');
      margin-bottom: 0.25rem;
      font-weight: 600;
      color: $ts-container-on-container-primary-1;
      line-height: 1.25rem;

      @media screen and (max-width: 350px) {
        font-size: 0.9rem;
      }
    }

    .form-block {
      select + label {
        @include body-copy(medium);
        position: absolute;
        top: 1.75rem;
        left: 1.25rem;
        right: 2.5rem;
        background-color: $ts-color-white;
        font-size: 1rem;
        color: $ts-container-on-container-primary-2;
        transform: translateY(-50%);
        overflow: hidden;
        pointer-events: none;

        @media screen and (max-width: 350px) {
          top: 1.625rem;
          font-size: 0.9rem;
        }
      }

      select {
        background-image: url("data:image/svg+xml,%0A%3Csvg width='18' height='11' viewBox='0 0 18 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.5 1.25L9 8.75L1.5 1.25' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
        color: $ts-container-on-container-primary-2;

        @media screen and (max-width: 350px) {
          height: 3rem;
          font-size: 0.9rem;
        }

        cursor: pointer;
        &:active,
        &:focus {
          background-image: url("data:image/svg+xml,%0A%3Csvg width='18' height='11' viewBox='0 0 18 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 9.75L9 2.25L16.5 9.75' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
        }

        option {
          display: flex;
          padding: 0.75rem 1.25rem;
          align-items: center;
          gap: 5rem;
          align-self: stretch;
          display: flex;
          align-items: center;
          gap: 5rem;
          align-self: stretch;
          color: $ts-color-black;
          .language-name-dp-item {
            font-family: Arial;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.375rem; /* 137.5% */
            text-decoration-line: underline;
          }
          &:disabled {
            color: $ts-color-gray-50;
          }
        }
      }
    }
    // .form-block select-active {

    // }
    .form-block-wrapper.not-active {
      opacity: 0.5;
      pointer-events: none;
      filter: grayscale(1);
    }
  }

  &__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    svg {
      color: $ts-color-black;
    }

    &--btn {
      width: 1rem;
      height: 1rem;
    }
  }

  &__body {
    .dropdown__region,
    .dropdown__language {
      select {
        margin: 0.5rem 0;
      }
      .form-block {
        margin-bottom: 1.5rem;
      }
    }
  }

  &__footer {
    // margin-top: 2.75rem;
    text-align: center;
    background-color: $ts-background-secondary;
    display: flex;
    padding: 0.75rem 1.25rem 1.25rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 1.125rem;
    align-self: stretch;
    a {
      @include body-copy(medium);
      padding-bottom: 0.125rem;
      border-bottom: 0.0625rem solid $ts-color-black;
      font-size: 1.25rem;
      color: white;
      text-decoration: none;
      &:hover {
        color: white;
      }

      @include respond-below(sm) {
        font-size: 1rem;
      }
    }
    button {
      a {
        color: white;
        &:hover {
          color: $ts-color-black;
        }
      }
    }
  }
}
