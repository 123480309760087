@import '../../../../scss/colors';
@import '../../../../scss/mixins';

$small-padding: 20px;
$large-padding: 60px;

.dt-step2 {
  $block: &;
  position: relative;
  z-index: 1;

  &--2-1 {
    display: flex;
    height: 100%;

    .dt-breadcrumb {
      margin-bottom: 32px;

      @include respond-above(md) {
        margin-bottom: 0;
      }
    }
  }

  &__container {
    width: 100%;

    #{$block}--2-1 & {
      @include respond-above(md) {
        flex-basis: 55%;
        padding: $large-padding;
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 28px;

    #{$block}--2-1 & {
      display: grid;
      gap: 0;

      @include respond-above(md) {
        display: flex;
        gap: 37px;
      }
    }

    #{$block}--2-2 & {
      @include respond-above(md) {
        gap: 80px;
      }
    }
  }

  &__image {
    grid-area: 1 / 1 / 4 / 2;
    margin-bottom: 32px;
    width: 100%;
    height: 240px;
    border-radius: 0.5rem 0.5rem 0 0;
    object-fit: cover;

    @include respond-above(md) {
      margin-bottom: 0;
      height: 100%;
      border-radius: 0 0.5rem 0.5rem 0;
      border-bottom: none;
    }
  }

  &__btn-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 28px;

    @include respond-above(md) {
      justify-content: flex-start;
      align-items: center;
      gap: 70px;

      .back-button {
        order: 0;
      }

      .dt-breadcrumb {
        order: 1;
      }

      .close-button {
        order: 2;
        margin-left: auto;
      }
    }

    #{$block}--2-1 & {
      @include respond-below(md) {
        padding: $small-padding;
        grid-area: 1 / 1 / 3 / 2;
      }

      @include respond-above(md) {
        flex-flow: row;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  &__title-container {
    @include respond-above(md) {
      background: radial-gradient($ts-background-primary, transparent);
    }

    #{$block}--2-1 & {
      @include respond-below(md) {
        padding-left: $small-padding;
        padding-right: $small-padding;
      }
    }
  }

  &__sub-title {
    @include label(small);
    margin-bottom: 10px;
    color: $ts-background-on-primary-2;

    @include respond-above(md) {
      margin-bottom: 12px;

      #{$block}--2-2 & {
        text-decoration: underline;
      }
    }
  }

  &__title {
    @include headline(4);
    margin-bottom: 32px;
    color: $ts-background-on-primary-1;

    #{$block}--2-1 & {
      margin-bottom: 36px;
    }

    @include respond-above(md) {
      @include headline(3);
      margin-bottom: 40px;

      #{$block}--2-2 & {
        margin-bottom: 44px;
      }
    }
  }

  &__card-container {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include respond-above(md) {
      flex-direction: row;
    }

    #{$block}--2-1 & {
      @include respond-below(md) {
        gap: 26px;
        padding-right: $small-padding;
        padding-bottom: $small-padding;
        padding-left: $small-padding;
      }
      @include respond-above(md) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 38px;
        column-gap: 60px;
      }
    }
  }

  &__img-container {
    position: relative;

    @include respond-above(md) {
      flex-basis: 45%;
    }

    .close-button {
      position: absolute;
      top: 60px;
      right: 60px;
    }
  }
}
