@import '../../../scss/colors';
@import '../../../scss/mixins';
@import '../../../globals/typography/typography.scss';

.card-block {
  //     padding: 0 5rem 0 5rem;
  padding: 3.75rem 1.25rem;
  @include respond-between(md, xl) {
    padding: 5rem 5rem;
  }
  @include respond-above(xl) {
    padding: 5rem 5rem;
  }
  &.bg-primary {
    color: $ts-background-on-primary-1;
    background: $ts-background-primary;
  }
  &.bg-secondary {
    color: $ts-background-on-secondary-1;
    background: $ts-background-secondary;
  }

  &.benefits {
    padding: 5rem;
    @include respond-below(md) {
      padding: 3.75rem 1.25rem;
    }
    
    .section-header {
      justify-content: center;
      text-align: center;
      margin-bottom: 0.5rem;
      @include respond-above(sm) {
        margin-bottom: 60px;
      }
      .sub-title {
        color: $ts-background-on-primary-2;
        margin: 0;
      }
    }
    &__1 {
      .card {
        flex-basis: 100%;
      }
    }
    &__3 {
      .card {
        @include respond-above(sm) {
          flex-basis: calc(33.33% - 2rem);
        }
      }
    }
    &__2,
    &__4 {
      .card {
        flex-basis: 100%;
        @include respond-above(sm) {
          flex-basis: calc(50% - 2rem);
        }
      }
    }
    &__5 {
      .card {
        @include respond-between(sm, lg) {
          flex-basis: calc(50% - 2rem);
        }
        &:nth-child(4),
        &:nth-child(5) {
          flex-basis: 100%;
          @include respond-above(sm) {
            flex-basis: calc(50% - 2rem);
          }
        }
      }
    }
    &__7 {
      .card {
        @include respond-between(sm, md) {
          flex-basis: calc(50% - 2rem);
        }
        @include respond-above(md) {
          flex-basis: calc(33.33% - 2rem);
        }
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
          flex-basis: 100%;
          @include respond-above(sm) {
            flex-basis: calc(50% - 2rem);
          }
        }
      }
    }
    &__8 {
      .card {
        @include respond-between(sm, lg) {
          flex-basis: calc(50% - 2rem);
        }
        &:nth-child(7),
        &:nth-child(8) {
          flex-basis: 100%;
          @include respond-above(md) {
            flex-basis: calc(50% - 2rem);
          }
        }
      }
    }
    &__6,
    &__9 {
      .card {
        &.benefit {
          margin: 3.75rem 0px 0px 0px;
          @include respond-below(md) {
            margin: 2rem 0px 0px 0px;
          }
        }
        flex-basis: 100%;
        @include respond-above(sm) {
          flex-basis: calc(50% - 2rem);
        }
        @include respond-above(md) {
          flex-basis: calc(33.33% - 2rem);
        }
      }
    }
    .card-wrapper{
      justify-content: space-between;
      padding: 0 12px;
      .flex-fitcontent.benefit{
        padding: 0;
        .card-body{
          padding: 0;
          .description{
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &.section-event {
    padding: 3.75rem 1.25rem;
    @include respond-between(md, xl) {
      padding: 5rem 4rem;
    }
    @include respond-above(xl) {
      padding: 5rem 0;
    }
  }
  &.events {
    padding: 3.75rem 0 4rem;
    @include respond-between(md, xl) {
      padding: 5rem 4rem;
    }
    @include respond-above(xl) {
      padding: 5rem 0;
    }
    .section-header {
      .sub-title {
        color: $ts-background-on-primary-2;
      }
    }
    .card {
      &__insights {
        .card-body {
          padding-top: 2.5rem;
          &.event__listing__card__body {
            padding: 2.5rem 2.5rem 3rem 2.5rem;
            @include mobile {
              padding: 2rem 1.5rem;
            }
          }
        }
        .action-bar {
          .tag-wrapper {
            .tag {
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }
  &.insights {
    &.__insights__listing {
      @include respond-above(md) {
        padding: 2.5rem 5rem;
      }
    }
    .card-wrapper {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      @include respond-above(lg) {
        flex-direction: row;
        gap: 1.25rem;
      }
    }
    &__callout {
      padding: 3.75rem 1.25rem;
      @include respond-between(md, xl) {
        padding: 5rem 5rem;
      }
      @include respond-above(xl) {
        padding: 5rem 0;
      }
      &.featured {
        .card {
          &__featured {
            @include respond-above(md) {
              flex-basis: calc(60% - 1rem);
            }
          }
          &__callout {
            @include respond-above(md) {
              flex-basis: calc(40% - 1rem);
            }
          }
        }
      }
    }
    &__listing {
      @include respond-between(md, xl) {
        // margin: 2.5rem 5rem 2.5rem 5rem; //Do not change // removed by kavitha and suma as padding is adding
      }
      @include respond-above(xl) {
        padding: 5rem 0;
      }
      .card-wrapper {
        padding: 1.25rem 0 5rem;
        @include respond-above(xl) {
          padding: 2rem 0 5rem;
        }
      }
      .columns {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1.25rem;
        .card {
          width: 100%;
          flex-basis: 100%;
          margin: 0;
          .card-body {
            @include respond-above(md) {
              height: 19rem; //do not change
              padding: 2.25rem 2rem 2rem 2rem;
              font-family: Arial;
            }
          }
        }
        &__2 {
          .card {
            @include respond-above(sm) {
              flex-basis: calc(50% - 1.25rem);
            }
          }
        }
        &__3 {
          .card {
            @include respond-above(xs) {
              flex-basis: calc(50% - 1.25rem);
            }
            @include respond-above(md) {
              flex-basis: calc(33.33% - 1.25rem);
            }
            .card-body {
              .card-content {
                // margin-bottom: 1rem;
              }
            }
          }
        }
        &__4 {
          .card {
            @include respond-above(xs) {
              flex-basis: calc(50% - 1.25rem);
            }
            @include respond-above(md) {
              flex-basis: calc(25% - 1.25rem);
            }
            .card-body {
              .card-content {
                margin-bottom: 2.25rem;
              }
            }
          }
        }
      }
      .card {
        .img-wrapper {
          background-position: top right;
          object-fit: fill;
          // @include aspect-ratio(16, 9); // showed warning so commented aspect ratio can be added back again later
          background-repeat: no-repeat;
          background-size: contain;
          background-color: $ts-background-primary;
        }

        &:nth-child(odd) {
          .img-wrapper {
            background-image: url('../../../static/images/bg-card-insights-full-odd.svg');
          }
        }
        &:nth-child(even) {
          .img-wrapper {
            background-image: url('../../../static/images/bg-card-insights-full-even.svg');
            background-position: top right;
          }
        }
      }
      .pagination-wrapper {
        margin-top: 3.75rem;
        @include respond-above(md) {
          margin-top: 5rem;
        }
      }
    }
  }
  &.image-block {
    padding: 3.75rem 0 0 0;
    text-align: center;
    @include respond-between(md, xl) {
      padding: 5rem 4rem;
    }
    @include respond-above(xl) {
      // padding: 5rem 0;
    }

    .image-wrapper {
      flex-basis: 30%;
      margin: 0.25rem;
      @include respond-above(lg) {
        flex-basis: 20%;
        margin: 1rem;
      }
      img {
        width: 100%;
        height: auto;
        max-height: 100%;
      }
      .utility-icon.no-link {
        pointer-events: none;
      }
    }
    &__4 {
      .image-wrapper {
        flex-basis: calc(50% - 0.5rem);
        @include respond-above(lg) {
          flex-basis: calc(25% - 2rem);
        }
      }
    }
  }
  &.offerings {
    .header-wrapper {
      @include respond-above(md) {
        display: flex;
        margin-bottom: 3.75rem;
        flex-direction: row;
      }
      @include respond-below(lg) {
        margin-bottom: 2.5rem;
      }

      .sub-title {
        color: $ts-background-on-primary-2;
        margin: 0.75rem 0 0;
      }
      .cta-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        margin: 1.5rem 0 0;
        width: 100%;
        @include respond-above(md) {
          align-items: center;
        }
      }
      .cta-wrapper-offering {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        margin: 0;
        width: 100%;
        @include respond-above(md) {
          align-items: center;
        }
        @include mobile {
          margin-top: 1.5rem;
        }
      }
    }
    .columns {
      display: flex;
      flex-wrap: wrap;
      gap: 1.25rem;
      .card {
        width: 100%;
        flex-basis: 100%;
        margin: 0;
      }
      &__2 {
        .card {
          @include respond-above(md) {
            flex-basis: calc(50% - 1.25rem);
          }
        }
      }
      &__3 {
        .card {
          @include respond-above(md) {
            flex-basis: calc(50% - 1.25rem);
          }
          @include respond-above(lg) {
            flex-basis: calc(33.33% - 1.25rem);
          }
        }
      }
    }
  }
  &.profile {
    .section-header {
      text-align: center;
      justify-content: center;
      margin-bottom: 0.75rem;
      @include respond-above(md) {
        margin-bottom: 2rem;
      }
      &.teamprofile {
        margin-bottom: 1.25rem;
      }
    }
    .card-wrapper {
      text-align: center;
      // justify-content: center;
      justify-content: space-evenly;
      align-items: flex-start;
      min-width: 100%;
      @include respond-below(xs) {
        gap: 2.5rem 0;
      }
      .card {
        flex-basis: calc(100% - 1.5rem);
        @include respond-above(xs) {
          flex-basis: calc(50% - 1.5rem);
        }
        @include respond-above(sm) {
          flex-basis: calc(33.33% - 1.5rem);
        }
        @include respond-above(lg) {
          flex-basis: calc(25% - 1.5rem);
        }
      }
    }
    @include respond-above(lg) {
      &__3,
      &__5,
      &__6 {
        .card-wrapper {
          .card {
            flex-basis: calc(33.33% - 1.5rem);
          }
        }
      }
    }
  }
  &.solutions {
    &.sub-component {
      padding-top: 0;
      .header-wrapper {
        margin-bottom: 1.5rem;
        @include respond-above(lg) {
          margin-bottom: 2.5rem;
        }
        .title {
          @include headline(3);
          margin-bottom: 0.75rem;
        }
      }
    }
    .header-wrapper {
      display: flex;
      align-items: center;
      margin: 0 0 3.75rem 0;
      @include respond-below(lg) {
        margin: 0 0 2.5rem 0;
      }
      &.service {
        margin: 0 0 3.75rem 0;
        @include respond-below(lg) {
          margin: 0 0 2.5rem 0;
        }
      }
      @include respond-above(lg) {
        flex-direction: row;
        margin: 0 0 3.75rem;
      }
      .title {
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 44px; /* 110% */
        letter-spacing: -1.5px;
        margin-bottom: 0.75rem;
        @include respond-below(lg) {
          margin-bottom: 0.75rem;
          font-size: 32px;
          line-height: 36px; /* 112.5% */
          letter-spacing: -0.5px;
        }
      }
      .title-service {
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 44px; /* 110% */
        letter-spacing: -1.5px;
        margin-bottom: 0;
        @include respond-below(lg) {
          font-size: 32px;
          line-height: 36px; /* 112.5% */
          letter-spacing: -0.5px;
        }
      }

      .sub-title {
        color: $ts-background-on-primary-2;
        margin: 0;
      }
      .sub-title-service {
        color: $ts-background-on-primary-2;
        margin-top: 0.75rem;
        margin-bottom: 0;
        @include respond-below(lg) {
          margin-bottom: 2.5rem;
        }
      }
      .cta-wrapper {
        display: flex;
        justify-content: flex-start;
        margin: 1.5rem 0 0;
        width: 100%;
        justify-content: end;
        @include respond-below(lg) {
          justify-content: start;
          margin: 1.5rem 0 2.5rem 0;
        }
      }
    }
    .content-wrapper {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      height: 100%;
      position: relative;
      flex: 0 0 100%;
      max-width: 100%;
      gap: 1.5rem 1rem;
      @include respond-above(md) {
        flex-direction: row;
        // align-items: center;
      }
      @include respond-below(md) {
        flex-wrap: nowrap;
      }
    }
    &__callout {
      .content-wrapper {
        gap: 1.25rem;
        @include respond-above(md) {
          flex-wrap: nowrap;
          gap: 1.25rem;
        }
        .card-solution-anchor {
          text-decoration: none;
        }
        //commenting to remove overlapping of last card of Service Cardblock with carousal
        // &.service {
        //   height: 230px;
        // }
      }
    }
  }
  &.services {
    .header-wrapper {
      padding-bottom: 3.75rem;
      align-items: center;
      @include respond-below(md) {
        padding-bottom: 0;
      }
      .section-header {
        @include respond-below(md) {
          margin-right: 0px;
        }

        .title {
          margin-bottom: 12px;
        }
        .sub-title {
          color: $ts-background-on-primary-2;
          margin: 0;

          @include respond-above(md) {
            margin-bottom: 3.75rem;
          }
        }
      }
      .cta-wrapper {
        @include respond-below(md) {
          display: flex;
          justify-content: flex-end;
          margin: 1.5rem 0 0;
        }
      }
    }
    &__2 {
      .content-wrapper {
        .card.service-group {
          @include respond-below(md) {
            flex-basis: 100%;
          }
          @include respond-above(md) {
            flex-basis: calc(50% - 1.25rem);
          }
        }
      }
    }
    &__3 {
      .content-wrapper {
        .card.service-group {
          @include respond-below(md) {
            flex-basis: 100%;
          }
          @include respond-above(md) {
            flex-basis: calc(33.33% - 1.24rem);
          }
        }
      }
    }
    &__4 {
      .content-wrapper {
        .card.service-group {
          @include respond-below(md) {
            flex-basis: 100%;
          }
          @include respond-above(md) {
            flex-basis: calc(50% - 1.25rem);
          }
        }
      }
    }
    &__5 {
      .content-wrapper {
        .card.service-group {
          @include respond-below(md) {
            flex-basis: 100%;
          }
          @include respond-above(md) {
            flex-basis: calc(33.33% - 1.25rem);
            &:nth-child(4),
            &:nth-child(5) {
              flex-basis: calc(50% - 1.25rem);
            }
          }
        }
      }
    }

    .content-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1.25rem;
      @include respond-above(md) {
        gap: 1.5rem 1rem;
      }

      .card.service.service-group {
        @include respond-below(md) {
          margin-bottom: 1.25rem;
        }
      }

      .card-header {
        @include respond-below(md) {
          padding: 0;
        }
        img {
          @include respond-below(md) {
            width: 32px;
            height: 32px;
            margin-right: 0.5rem;
          }
          @include respond-above(md) {
            width: 64px;
            height: 64px;
          }
        }
      }
    }
    .content-wrapper-servicegroup {
      display: block;
    }
  }
}

a.card-link {
  text-decoration: none;
}
.card.Blue {
  background-color: #5c4bb9;
  color: #fff;
}

@include respond-above(lg) {
  h2.title-image {
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px; /* 110% */
    letter-spacing: -1.5px;
  }
  h2.title-image.achievement-title {
    justify-content: center;
  }
}
@include respond-below(lg) {
  h2.title-image {
    font-size: 32px;
    line-height: 36px; /* 112.5% */
    letter-spacing: -0.5px;
  }
}

.calloutbar-section {
  &.footer {
    padding: 5rem;
    @include mobile {
      padding: 3.75rem 1.25rem;
    }
  }
}
.calloutbar-section {
  &.footerAction {
    padding: 5rem;
    @include respond-below(lg) {
      padding: 2.5rem 1.25rem;
    }
  }
}
.card-block.offerings .columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  gap: 1.25rem;
  .card.card-offerings-solution {
    width: 100%;
    flex-basis: 100%;
    margin: 0;
    .card-body {
      padding: 3rem 3rem 4.25rem 3rem;
      @include respond-below(lg) {
        padding: 1.75rem 1.75rem 3rem 1.75rem;
      }
    }
  }
  &__2 {
    .card.card-offerings-solution {
      @include respond-above(sm) {
        flex-basis: calc(50% - 1.25rem);
      }
    }
  }
  &__3 {
    .card.card-offerings-solution {
      @include respond-above(xs) {
        flex-basis: calc(50% - 1.25rem);
      }
      @include respond-above(md) {
        flex-basis: calc(33.33% - 1.25rem);
      }
      .card.card__offering {
        .card-content {
          margin-bottom: 1rem;
        }
      }
    }
  }
  &__4 {
    .card.card-offerings-solution {
      @include respond-above(xs) {
        flex-basis: calc(50% - 1.25rem);
      }
      @include respond-above(md) {
        flex-basis: calc(33.33% - 1.25rem);
      }
      .card-body {
        .card-content {
          margin-bottom: 2.25rem;
        }
      }
    }
  }
  &__5 {
    .card.card-offerings-solution {
      @include respond-above(xs) {
        flex-basis: calc(50% - 1.25rem);
      }
      @include respond-above(md) {
        flex-basis: calc(33.33% - 1.25rem);
      }
      .card.card-offerings-solution {
        .card-content {
          margin-bottom: 1rem;
        }
      }
      .card-body .tags label.small-offering {
        padding-top: 0px;
      }
    }
  }
  &__6 {
    .card.card-offerings-solution {
      @include respond-above(xs) {
        flex-basis: calc(50% - 1.25rem);
      }
      @include respond-above(md) {
        flex-basis: calc(33.33% - 1.25rem);
      }
      .card.card-offerings-solution {
        .card-content {
          margin-bottom: 1rem;
        }
      }
      .card-body .tags label.small-offering {
        padding-top: 0px;
      }
    }
  }
}

.card-block.services {
  .header-wrapper .cta-wrapper {
    @include respond-above(sm) {
      text-align: left;
    }
    @include respond-above(md) {
      text-align: right;
    }
  }
}
a.card-link:hover {
  text-decoration: none;
}

.card-block.services {
  .header-wrapper .cta-wrapper {
    @include respond-above(sm) {
      text-align: left;
    }
    @include respond-above(md) {
      text-align: right;
    }
  }
}
a.card-link:hover {
  text-decoration: none;
}

// .card-body-image {
//   width: 25.75rem;
//   align-self: stretch;
//   flex-shrink: 0;
//   height: 232px;
// }
.card-body-image img {
  width: 100%;
  align-self: stretch;
  flex-shrink: 0;
  height: 100%; //do not change
  position: relative;
  top: 0;
}
.card-content {
  display: flex;
  height: 9.5rem;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
  flex: 1 0 0;
}
.card-content-carousel {
  display: flex;
  height: 9.5rem;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
  flex: 1 0 0;
}
.card-content .sub-title {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @include respond-below(md) {
    max-width: 358px;
  }
  margin-bottom: 1.875rem;
  // flex-direction: column;
  //   align-items: flex-start;
}
.carins .button.tag {
  margin-right: 8px;
}
.card-content .sub-title p {
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  margin-bottom: 0.75rem;
}
.card-content .title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.006rem; //do not change
  max-width: 312px;
  line-height: 118%;
  @include respond-below(md) {
    line-height: 111%;
    max-width: 358px;
  }
}
.card-content p {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
}
.card__full {
  .cttagcontainer {
    padding-bottom: 0.75rem;
  }
  &.card-body .card-content p {
    margin: 0rem;
    &.no-filter {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}
.title-benefits,
.title-service {
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px; /* 110% */
  letter-spacing: -1.5px;
  margin-bottom: 0.75rem;
  &.mb-0 {
    margin-bottom: 0;
  }
  @include respond-below(lg) {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 112.5% */
    letter-spacing: -0.5px;
  }
}

.sub-title-service {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 12px;
  margin-bottom: 0;
}
.no-border a .button.secondary-button:hover {
  border: none;
}
.card-offerings {
  .title {
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: -1.5px;
  }
}

.card-block.services .header-wrapper {
  @include respond-below(md) {
    padding-bottom: 40px;
  }
}
.card-block.services .header-wrapper .section-header .title {
  @include respond-below(md) {
    margin-bottom: 24px;
  }
}
.card.service.service-group .card-header {
  @include respond-below(md) {
    width: calc(100% - 1rem);
  }
  @include respond-above(md) {
    padding: 0;
  }
}
.card.service.service-group.active .card-header {
  @include respond-below(md) {
    margin-top: 0;
  }
}
.card.service .card-header .title:after {
  @include respond-below(md) {
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
}
.card.service.service-group .card-link {
  @include respond-above(md) {
    height: 100%;
  }
}
.card.service.service-group .card-body .card-content-service {
  justify-content: unset;
  @include respond-above(md) {
    padding: 0;
    margin-top: 20px;
  }
  .buttonhref-anchor {
    margin-top: auto;
  }
}
.card.service .card-body .card-content-service .sub-title-service {
  @include respond-above(md) {
    @include label(small);
    margin: 0;
  }
}
.card.service .card-body .card-content-service button {
  @include respond-above(md) {
    margin-top: auto;
    margin-bottom: 0;
  }
}
.card-block.solutions.pad-top-0 {
  padding-top: 0;
}
h2.title.xs-mobile-0 {
  margin-bottom: 0;
  @include respond-above(sm) {
    margin-bottom: 12px;
  }
  @include respond-below(sm) {
    margin-bottom: 12px;
    line-height: 36px;
  }
}
.card__insights .card-body .card-content p.mb-20 {
  margin: 0;
  margin-bottom: 20px;
}

.title_teamProfile {
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px; /* 110% */
  letter-spacing: -1.5px;
  @include respond-below(md) {
    font-size: 32px;
    line-height: 36px; /* 112.5% */
    letter-spacing: -0.5px;
  }
}

.card-block.insights.insights__listing {
  padding: 40px 80px;
  @include respond-below(lg) {
    padding: 40px 20px;
  }
}

p.color-lightgray {
  color: $color-lightgray;
}

.row.card-wrapper.xs-pad-lr-20{
  padding: 0 20px;
}