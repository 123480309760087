@import './../../../../scss/colors';
@import './../../../../scss/mixins';
@import './../../../../scss/variables';
@import './../../../scss/fonts';
// @import './../../../scss/base';

.loader {
  margin: 100px 0;
}
.customLoader {
  padding: 1.875rem;
}
.snackBarContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  .snackbar {
    visibility: hidden;
    min-width: 250px;
    // margin-left: -125px;
    color: $white;
    text-align: center;
    border-radius: 2px;
    padding: 1rem;
    position: fixed;
    // left: 50%;
    top: 97px;
    // bottom: 1.875rem;
    font-size: 1rem;
    z-index: 99;
    &.success {
      background-color: $green;
    }
    &.error {
      background-color: $color-maroon;
    }
  }

  .snackbar.fadeIn {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
  }

  .snackbar.fadeOut {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
  }
}

.no-data {
  margin: 1.875rem 0 2.5rem 0;
  .alert-heading {
    line-height: 1.875rem;
    font-family: $font-heading;
    font-size: 1.5rem;
  }
}

.alert-inline {
  text-align: left;
  p {
    line-height: 1.25rem;
    margin: 0.625rem 0;
  }
  &.error {
    color: $color-maroon;
  }
  &.success {
    color: $green;
  }
}

.overlay {
  background: $color-black-light;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 99;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  padding-top: 25%;
  // opacity: 0.25;
  .spinner-grow {
    vertical-align: middle;
    margin-right: 0.625rem;
  }
  &.opaque-bg {
    background: $color-light-border;
  }
}

.overlayCustom {
  background: $color-black-light;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 99;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  padding-top: 25%;
  // opacity: 0.3;
  .loadingText {
    color: $white;
    font-size: 1.875rem;
    padding: 5px;
  }
  .spinner-grow {
    vertical-align: middle;
    margin-right: 0.625rem;
    color: white;
  }
}
.alert-danger {
  color: $color-maroon !important;
  background-color: $color-pale-pink;
  border-color: $color-orchid-pink;
  padding: 0.75rem 1.5rem;
  & > p {
    color: inherit !important;
    font-size: inherit !important;
  }
}
.alert-popup-body p {
  text-align: center;
  font-size: 1.25rem;
}
.alert-popup-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  div:not(:first-child) {
    margin-left: 2.5rem;
  }
}
.refresh-button {
  justify-content: flex-end;
  display: flex;
  border-top: 1px solid rgba(157, 50, 61, 0.5);
  padding: 1.5rem 0.75rem 0.75rem;
  margin: 1.25rem 0 0;
  .dark-red {
    color: inherit;
    border-color: $color-maroon;
    padding: 0.625rem;
    text-decoration: none;
    &:hover {
      color: $white;
      background-color: $color-maroon;
    }
  }
}
