@import './../../../scss/mixins';
@import './../../../scss/fonts';
@import './../../../scss/colors';
@import './../../../globals/typography/typography.scss';

.site-footer {
  color: #282a32;
  margin: 2.5rem 1.25rem;
  padding-top: 2.5rem;
  border-top: 1px solid #c9cacc;
  .container {
    padding-top: 2.5rem;
    border-top: 1px solid #c9cacc;
  }
  .title {
    @include heading(5);
    color: #386097;
    font-size: 1rem;
    font-weight: 700;
    padding: 0;
    padding-bottom: 1.5rem;
  }

  .newsLetter {
    color: var(--tokens-background-on-primary-1, var(--color-black, #000));
    /* TS/hyperlink/small */
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    text-decoration-line: underline;
    padding: 0;
  }

  .sub-title {
    font-weight: 700;
    color: #4c79af;
    font-size: 0.8125rem;
    margin-bottom: 0.25rem;
  }
  .footer-nav {
    border-right: 1px solid #c9cacc;
    @include tablet {
      border-right: 0;
    }
    .footer-nav-block {
      margin-bottom: 1.25rem;
      margin-right: 0.875rem;
      nav {
        // margin-bottom: 1.25rem;
      }
    }
    a {
      display: block;
      text-transform: capitalize;
      color: #282a32;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.9;
      font-family: $font-family-1;
      letter-spacing: normal;
      text-decoration: none;
      padding: 0 0.625rem;
      &:hover {
        color: $color-darkgray-75;
      }
      &.sub-title {
        font-weight: 700;
        color: #4c79af !important;
        font-size: 0.75rem;
        margin-bottom: 0.25rem;
        letter-spacing: normal;
      }
    }
  }
  .footer-rightside {
    padding-left: 60px;
    padding-left: 0.5rem;
    @include tablet {
      padding-left: 0;
    }
  }
  .footer-country {
    margin-top: 2rem;
    .title {
      padding-top: 0.25rem;
      margin-right: 1rem;
    }
    .country {
      color: #282a32 !important;
      text-transform: capitalize;
      font-weight: 400;
      text-decoration: underline !important;
    }
    .col {
      @include flexbox;
    }
  }
  .footer-disclaimer {
    text-align: center;
    padding: 5rem 0 3.5rem;
    margin-top: 3.75rem;
    color: $white;
    background-color: #282a32;
    .container {
      padding-top: 0;
      border-top: none;
    }
    p {
      font-size: 0.75rem;
      line-height: 2;
    }
    .footer-disclaimer-links {
      a {
        position: relative;
        display: inline-block;
        margin-left: 0.625rem;
        font-size: 0.6875rem;
        text-transform: capitalize;
        color: $white !important;
        font-weight: 400;
        font-family: 'Inter';
        letter-spacing: normal;
        text-decoration: none;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
        &:after {
          content: '|';
          display: inline-block;
          margin-left: 0.625rem;
        }
        &:hover {
          color: $mpg-color-gray-25 !important;
        }
      }
    }
    .footer-disclaimer-icons {
      @include flexbox;
      @include justify-content(center);
      @include flex-wrap(wrap);
      padding: 0;
      margin-bottom: 3.75rem;
      @include mobile {
        margin-bottom: 0;
      }
      .icon {
        padding: 0;
        margin: 0;
        img {
          display: block;
          margin: 0;
        }
        &.icon--1,
        &.icon--2,
        &.icon--3,
        &.icon--4,
        &.icon--5 {
          @include flexbox;
          @include justify-content(center);
          @include align-items(flex-start);
          height: unset;
          @include mobile {
            margin-bottom: 2.25rem;
          }
        }
        &.icon--1 {
          flex: 0 1 100%;
          margin-bottom: 2.25rem;
        }
        &.icon--2 {
          @include justify-content(flex-end);
          flex: 1 0 40%;
          @include respond-below(xs) {
            // flex: 0 1 100%;
            @include justify-content(center);
          }
        }
        &.icon--3 {
          flex: 1 0 20%;
          // @include   mobile {
          // flex: 0 1 100%;
          // }
        }
        &.icon--4 {
          @include justify-content(flex-start);
          flex: 1 0 40%;
          @include respond-below(xs) {
            // flex: 0 1 100%;
            @include justify-content(center);
          }
        }
      }
    }
    .footer-bottom-links {
      margin-top: 3.5rem;
      padding-top: 2.75rem;
      border-top: 1px solid $white;
      @include tablet {
        @include justify-content(center);
      }
      .copyright {
        text-align: left;
        @include tablet {
          order: 3;
          text-align: center;
          margin-top: 2rem;
        }
        p {
          font-size: 0.75rem;
          margin: 0;
        }
      }
      .social-follow {
        text-align: center;
        @include tablet {
          margin-bottom: 2rem;
        }
        .social-icon {
          @include tablet {
            margin-right: 2rem;
            &:last-child {
              margin-right: 0;
            }
          }
          img {
            height: auto;
            max-height: unset;
            width: auto;
            filter: brightness(0) invert(1);
          }
        }
      }
      .footer-lang-selector {
        @include flexbox;
        @include align-items(center);
        @include justify-content(flex-end);
        @include tablet {
          @include justify-content(center);
        }
        .lang-selector {
          @include flexbox;
          @include align-items(center);
          font-size: 0.8125rem;
          color: $white !important;
          text-transform: capitalize;
          letter-spacing: normal;
          font-weight: normal;
          margin-bottom: 0;
          background: transparent;
          border: 0;
          img {
            margin-right: 0.25rem;
          }
        }
      }
    }
  }
  .newsletter {
    p {
      color: #282a32;
      margin-top: 0;
    }
  }
  .footer-social {
    .col {
      @include flexbox;
      @include align-items(center);
      @include flex-wrap(wrap);
    }
    .title {
      margin-right: 1.25rem;
      white-space: nowrap;
    }
    .social-bar {
      @include flexbox;
      @include flex-direction(row);
      @include align-items(stretch);
      @include justify-content(center);
      @include mobile {
        @include align-items(center);
      }
      white-space: nowrap;
      .social-icon {
        margin-right: 1.25rem;
      }
    }
  }
}
// additional

p {
  //styleName: TS/Body Copy/Body Medium;
  color: $ts-background-on-primary-1;
  font-family: $font-family-2;
  font-size: 1rem;
  line-height: 1.375;
  margin: 1rem 0;
  font-weight: 400;
  letter-spacing: 0px;
  &.small {
    //styleName: TS/Body Copy/Body Small;
    font-size: 0.875rem;
    line-height: 1.42;
  }
  &.fine {
    //styleName: TS/Body Copy/Body Fine Print;
    font-size: 0.75rem;
    line-height: 1.66;
  }
  &.on-secondary,
  &.reversed {
    color: $white;
  }
  &.on-primary-2 {
    color: $ts-background-on-primary-2;
  }
  &.on-secondary-2 {
    color: $ts-background-on-secondary-2;
  }
}
.para > p {
  //styleName: TS/Body Copy/Body Medium;
  color: $color-lightgray;
  font-family: $font-family-2;
  font-size: 1rem;
  line-height: 1.375;
  font-weight: 400;
  letter-spacing: 0px;
  margin: 0;
  &.small {
    //styleName: TS/Body Copy/Body Small;
    font-size: 0.875rem;
    line-height: 1.42;
  }
  &.fine {
    //styleName: TS/Body Copy/Body Fine Print;
    font-size: 0.75rem;
    line-height: 1.66;
  }
  &.on-secondary,
  &.reversed {
    color: $white;
  }
  &.on-primary-2 {
    color: $ts-background-on-primary-2;
  }
  &.on-secondary-2 {
    color: $ts-background-on-secondary-2;
  }
}

.site-footer .footer-disclaimer {
  background-color: #000000;
}
.site-footer .footer-disclaimer p {
  color: #ffffff;
  font-size: 0.75rem;
  line-height: 2;
}
.social-icon {
  display: inline-block;
  margin: 0 1.5rem 0 1.5rem;
  transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.form-block {
  position: relative;
  margin-bottom: 0.75rem;
  textarea,
  input.hs-input {
    margin-right: 1.5em;
    @media screen and (max-width: 480px) {
      width: 100%;
      margin: 0 auto;
    }
    &.invalid.error {
      box-shadow: inset 3px 4px 12px $form-error-box-border-shadow-color;
      border-color: $form-error-box-border-color;
    }
  }
}
.form-block input {
  display: block;
  width: 100%;
  height: 3.375rem;
  line-height: 3.375rem;
  border: 1px solid #939498;
  border-radius: 0.5rem;
  background-color: #ffffff;
  color: #000000;
  font-size: 1rem;
  padding: 0 1.25rem;
  outline-color: transparent;
  transition: 0.33s cubic-bezier(0.215, 0.61, 0.355, 1);
  box-shadow: none;
  outline: none;
}
.button.text-button.signin {
  background-color: transparent;
  border: 0;
  padding: 0;
  padding: 0.5rem 0 0.125rem;
}
.button.text-button:before {
  content: '';
  position: absolute;
  bottom: -0.0625rem;
  width: 100%;
  // height: 0.0625rem;
  // background-color: #ffffff; // commented by Kavitha, this is affecting all inderline text for the button text-button variant, wrap this with parent component & change color
  transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.button.text-button.signpost .arrow-icon {
  width: 1.125rem;
  height: 1.125rem;
  background-size: 1.125rem;
  margin-left: 0.75rem;
  transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  position: relative;
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2IDlMLTIuMzg0MTllLTA3IDkiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIvPgo8cGF0aCBkPSJNOC45OTk5NyAyTDE2IDlMOC45OTk5NyAxNiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIi8+Cjwvc3ZnPgo=);
}
.footer-disclaimer {
  background-color: #000;
  text-align: center;
  padding: 4.625rem 4.063rem;
  padding-bottom: 3.75rem;
  @include respond-below(md) {
    padding: 3.75rem 1.25rem;
    padding-bottom: 3rem;
  }

  margin-bottom: 0;

  p {
    color: #fff;
    font-size: 0.75rem;
    line-height: 1.125rem;
    margin-bottom: 2rem;
  }
}
.footer-bottom-links .copyright {
  color: #fff;
}

/////////////

.footer-newsletter .title {
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-family: $font-family-1;
  font-weight: 600 !important;
  line-height: 16px; /* 100% */
  text-transform: uppercase;
  text-decoration: none;
}

.footer-newsletter p {
  color: #67696f;
  font-family: $font-family-2;
  margin-top: 0;
}

.footer-newsletter p.fine {
  font-size: 0.75rem;
  line-height: 1.66;
}

//Do not uncomment this - Kavitha. Affecting everywhere.
// .form-footer {
//   margin-top: -25px;
// }
