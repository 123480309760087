@import '../../../../scss//colors';
@import '../../../../scss/mixins';

$btn-transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);

.back-button {
  $block: &;
  position: relative;
  padding: 0.125rem 0.8125rem 0.125rem 3rem!important;
  height: 40px;
  background: $ts-action-small-primary;
  border-radius: 60px;
  border: 0.1875rem solid transparent;
  outline: none;
  box-shadow: 0 0 0 0.0625rem transparent inset;
  transition: $btn-transition;

  &.black-mobile {
    @include respond-below(md) {
      background-color: $ts-action-secondary;

      &:active {
        background-color: $ts-action-secondary;
      }
    }
  }

  &:hover,
  &:focus {
    border: 0.1875rem solid rgba(00, 00, 00, 0.2);
    outline: none;
    box-shadow: 0 0 0 0.0625rem rgba(00, 00, 00, 0.2) inset;

    #{$block}__icon {
      left: -3px;
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  &:active {
    background-color: $ts-action-small-primary;

    #{$block}__text {
      color: $ts-accent-1-primary;
    }
  }

  @include respond-above(md) {
    background-color: transparent;

    &:hover,
    &:focus {
      border: 0.1875rem solid transparent;
      outline: none;
      box-shadow: 0 0 0 0.0625rem transparent inset;

      #{$block}__text:before {
        background-color: $ts-accent-1-primary;
        height: 0.25rem;
        bottom: -0.25rem;
      }
    }

    &:active {
      background-color: transparent;
    }
  }

  &__icon {
    position: absolute;
    left: 0.25rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    background: $ts-action-secondary;
    color: $ts-action-on-small-primary;
    border-radius: 20px;

    @include respond-above(md) {
      background-color: $ts-action-small-primary;
    }

    .black-mobile & {
      @include respond-below(md) {
        background-color: $ts-action-primary;
        color: $ts-action-on-action-primary;
      }
    }
  }

  &__text {
    @include label(small);
    color: $ts-action-on-small-primary;

    @include respond-above(md) {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        bottom: -0.0625rem;
        width: 100%;
        height: 0.0625rem;
        background-color: $ts-action-primary;
        transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }

    .black-mobile & {
      @include respond-below(md) {
        color: $ts-action-on-action-secondary;
      }
    }
  }
}
