$orange: #b43700;
$red: #9d323d;
$violet: #4f4296;
$green: #5c7d70;
$blue: #386097;
$gray: #282a32;

$colors: map-remove(
  $colors,
  'blue',
  'indigo',
  'purple',
  'pink',
  'red',
  'orange',
  'yellow',
  'green',
  'teal',
  'cyan',
  'gray',
  'gray-dark'
);
$colors: map-merge(
  (
    'orange': $orange,
    'red': $red,
    'violet': $violet,
    'green': $green,
    'blue': $blue,
    'gray': $gray,
  ),
  $colors
);

$spacers: map-merge(
  (
    20: $spacer * 1.25,
    40: $spacer * 2.5,
  ),
  $spacers
);
$orange-100: tint-color($orange, 100%);
$orange-75: #c25700;
$orange-50: #e0690a;
$orange-25: #ff7a12;

$red-100: tint-color($red, 100%);
$red-75: #cc334d;
$red-50: #e4415f;
$red-25: #fc5c7d;

$violet-100: tint-color($violet, 100%);
$violet-75: #5c4bb9;
$violet-50: #736bde;
$violet-25: #9e94fa;

$green-100: tint-color($green, 100%);
$green-75: #669e66;
$green-50: #70bf5c;
$green-25: #a6eb8c;

$blue-100: tint-color($blue, 100%);
$blue-75: #4c79af;
$blue-50: #3893cd;
$blue-25: #56bded;

$gray-100: tint-color($gray, 100%);
$gray-75: #67696f;
$gray-50: #939498;
$gray-25: #efefef;

$oranges: (
  'orange-100': $orange-100,
  'orange-75': $orange-75,
  'orange-50': $orange-50,
  'orange-25': $orange-25,
);
$reds: (
  'red-100': $red-100,
  'red-75': $red-75,
  'red-50': $red-50,
  'red-25': $red-25,
);
$violets: (
  'violet-100': $violet-100,
  'violet-75': $violet-75,
  'violet-50': $violet-50,
  'violet-25': $violet-25,
);
$greens: (
  'green-100': $green-100,
  'green-75': $green-75,
  'green-50': $green-50,
  'green-25': $green-25,
);
$blues: (
  'blue-100': $blue-100,
  'blue-75': $blue-75,
  'blue-50': $blue-50,
  'blue-25': $blue-25,
);
$grays: (
  'gray-100': $gray-100,
  'gray-75': $gray-75,
  'gray-50': $gray-50,
  'gray-25': $gray-25,
);

$prefix: ts-;
$border-color: $gray-25;
$button-padding-y: 0.875rem;
$button-padding-x: 1.25rem;
$button-white-space: null !default;
$button-border-radius: 2.5rem;
$button-hover-border-width: 0.25rem;

$button-font-size: 1rem;
$button-line-height: 1.25rem;

$button-types: 'primary', 'secondary';
$button-mods: 'white', 'black', 'violet', 'outline', 'text';
$button-icon-mods: 'list', 'signpost', 'arrow';

$background-types: (
  light: (
    'bgcolor': $white,
    'txt-color': $black,
    'button-txt-color': $white,
    'button-bg-color': $black,
    'button-hover-opacity': 0.2,
    'button-hover-border-color': $black,

    'primary-button-txt-color': $white,
    'primary-button-bg-color': $black,
    'primary-button-bgactive-color': $gray,
    'primary-button-disabled-color': $gray-25,
    'primary-button-disabled-txt-color': $gray-50,

    'primary--black-button-txt-color': $white,
    'primary--black-button-bg-color': $black,
    'primary--black-button-bgactive-color': $gray,
    'primary--black-button-disabled-color': $gray-25,
    'primary--black-button-disabled-txt-color': $gray-50,

    'primary--violet-button-hover-border-color': $violet,
    'primary--violet-button-bg-color': $violet-75,
    'primary--violet-button-txt-color': $white,
    'primary--violet-button-bgactive-color': $violet,

    'primary--outline-button-box-shadow': 0 0 0 0.0625rem $gray-50 inset,
    'primary--outline-button-active-box-shadow': 0 0 0 0.0625rem $violet-75 inset,
    'primary--outline-button-disabled-box-shadow': 0 0 0 0.0625rem $gray-25 inset,
    'primary--outline-button-hover-border-color': $violet,
    'primary--outline-button-bg-color': $white,
    'primary--outline-button-txt-color': $black,
    'primary--outline-button-bgactive-color': $white,
    'primary--outline-button-disabled-color': $white,
    'primary--outline-button-disabled-txt-color': $gray-50,

    'primary--text-button-hover-border-color': $violet-75,
    'primary--text-button-bg-color': $white,
    'primary--text-button-txt-color': $black,
    'primary--text-button-button-border-color': $black,
    'primary--text-button-disabled-color': $white,
    'primary--text-button-disabled-txt-color': $gray-50,
    'primary--text-button-hover-border-width': 0.25rem,
    'primary--text-button-bgactive-color': $white,
    'primary--text-button-txtactive-color': $violet-75,
    'primary--text-button-borderactive-color': $violet-75,

    'primary--white--list-icon': url('../../assets/icons/standard/list_light.svg'),
    'primary--white--signpost-icon': url('../../assets/icons/standard/signpost_light.svg'),
    'primary--white--arrow-icon': url('../../assets/icons/standard/list_light.svg'),
    'primary--white--list-icon-disabled': url('../../assets/icons/standard/list_disabled.svg'),
    'primary--white--signpost-icon-disabled':
      url('../../assets/icons/standard/signpost_light_disabled.svg'),
    'primary--white--arrow-icon-disabled': url('../../assets/icons/standard/list_disabled.svg'),
    'primary--white--signpost-icon-hover': url('../../assets/icons/standard/signpost_hover.svg'),
    'primary--white--list-icon-active': url('../../assets/icons/standard/list_light.svg'),
    'primary--black--list-icon': url('../../assets/icons/standard/list_light.svg'),
    'primary--black--signpost-icon': url('../../assets/icons/standard/signpost_light.svg'),
    'primary--black--arrow-icon': url('../../assets/icons/standard/list_light.svg'),
    'primary--black--list-icon-disabled': url('../../assets/icons/standard/list_disabled.svg'),
    'primary--black--signpost-icon-disabled':
      url('../../assets/icons/standard/signpost_light_disabled.svg'),
    'primary--black--arrow-icon-disabled': url('../../assets/icons/standard/list_disabled.svg'),
    'primary--black--signpost-icon-hover': url('../../assets/icons/standard/signpost_hover.svg'),
    'primary--black--list-icon-active': url('../../assets/icons/standard/list_light.svg'),
    'primary--violet--list-icon': url('../../assets/icons/standard/list_light.svg'),
    'primary--violet--signpost-icon': url('../../assets/icons/standard/signpost_light.svg'),
    'primary--violet--arrow-icon': url('../../assets/icons/standard/list_light.svg'),
    'primary--violet--list-icon-disabled': url('../../assets/icons/standard/list_disabled.svg'),
    'primary--violet--signpost-icon-disabled':
      url('../../assets/icons/standard/signpost_light_disabled.svg'),
    'primary--violet--arrow-icon-disabled': url('../../assets/icons/standard/list_disabled.svg'),
    'primary--violet--signpost-icon-hover':
      url('../../assets/icons/standard/signpost_violet_hover.svg'),
    'primary--violet--list-icon-active': url('../../assets/icons/standard/list_light.svg'),
    'primary--outline--list-icon': url('../../assets/icons/standard/list_dark.svg'),
    'primary--outline--signpost-icon': url('../../assets/icons/standard/signpost_dark.svg'),
    'primary--outline--arrow-icon': url('../../assets/icons/standard/list_dark.svg'),
    'primary--outline--list-icon-disabled': url('../../assets/icons/standard/list_disabled.svg'),
    'primary--outline--signpost-icon-disabled':
      url('../../assets/icons/standard/signpost_dark_disabled.svg'),
    'primary--outline--arrow-icon-disabled': url('../../assets/icons/standard/list_disabled.svg'),
    'primary--outline--signpost-icon-hover': url('../../assets/icons/standard/signpost_dark.svg'),
    'primary--outline--list-icon-active': url('../../assets/icons/standard/list_dark.svg'),
    'primary--text--list-icon': url('../../assets/icons/standard/list_dark.svg'),
    'primary--text--arrow-icon': url('../../assets/icons/standard/arrow.svg'),
    'primary--text--list-icon-disabled': url('../../assets/icons/standard/list_disabled.svg'),
    'primary--text--list-icon-active': url('../../assets/icons/standard/list_active.svg'),
    'primary--text--arrow-icon-disabled': url('../../assets/icons/standard/arrow_disabled.svg'),
    'primary--text--arrow-icon-hover': url('../../assets/icons/standard/arrow.svg'),
    'primary--text--arrow-icon-active': url('../../assets/icons/standard/arrow_dark_active.svg'),
  ),
  dark: (
    'bgcolor': $black,
    'txt-color': $white,
    'button-txt-color': $white,
    'button-bg-color': $black,
    'button-hover-opacity': 0.4,
    'button-hover-border-color': $white,

    'secondary-button-txt-color': $black,
    'secondary-button-bg-color': $white,
    'secondary-button-bgactive-color': $white,
    'secondary-button-disabled-color': $gray,
    'secondary-button-disabled-txt-color': $gray-75,

    'secondary--white-button-txt-color': $black,
    'secondary--white-button-bg-color': $white,
    'secondary--white-button-bgactive-color': $white,
    'secondary--white-button-disabled-color': $gray,
    'secondary--white-button-disabled-txt-color': $gray-75,

    'secondary--outline-button-box-shadow': 0 0 0 0.0625rem $gray-75 inset,
    'secondary--outline-button-active-box-shadow': 0 0 0 0.0625rem $violet-75 inset,
    'secondary--outline-button-disabled-box-shadow': 0 0 0 0.0625rem $gray inset,
    'secondary--outline-button-hover-border-color': $violet,
    'secondary--outline-button-bg-color': $black,
    'secondary--outline-button-txt-color': $white,
    'secondary--outline-button-bgactive-color': $black,
    'secondary--outline-button-disabled-color': $black,
    'secondary--outline-button-disabled-txt-color': $gray-75,

    'secondary--violet-button-hover-border-color': $violet,
    'secondary--violet-button-bg-color': $violet-75,
    'secondary--violet-button-txt-color': $white,
    'secondary--violet-button-bgactive-color': $violet,

    'secondary--text-button-hover-border-color': $violet-75,
    'secondary--text-button-bg-color': $black,
    'secondary--text-button-txt-color': $white,
    'secondary--text-button-button-border-color': $black,
    'secondary--text-button-disabled-color': $black,
    'secondary--text-button-disabled-txt-color': $gray-75,
    'secondary--text-button-hover-border-width': 0.25rem,
    'secondary--text-button-bgactive-color': $black,
    'secondary--text-button-txtactive-color': $violet-75,
    'secondary--text-button-borderactive-color': $violet-75,

    'secondary--white--list-icon': url('../../assets/icons/standard/list_dark.svg'),
    'secondary--white--signpost-icon': url('../../assets/icons/standard/signpost_dark.svg'),
    'secondary--white--arrow-icon': url('../../assets/icons/standard/list_dark.svg'),
    'secondary--white--list-icon-disabled': url('../../assets/icons/standard/list_disabled.svg'),
    'secondary--white--signpost-icon-disabled':
      url('../../assets/icons/standard/signpost_light_bg_disabled.svg'),
    'secondary--white--arrow-icon-disabled': url('../../assets/icons/standard/list_disabled.svg'),
    'secondary--white--signpost-icon-hover':
      url('../../assets/icons/standard/signpost_light_bg_hover.svg'),
    'secondary--white--list-icon-active': url('../../assets/icons/standard/list_light.svg'),
    'secondary--black--list-icon': url('../../assets/icons/standard/list_dark.svg'),
    'secondary--black--signpost-icon': url('../../assets/icons/standard/signpost_dark.svg'),
    'secondary--black--arrow-icon': url('../../assets/icons/standard/list_dark.svg'),
    'secondary--black--list-icon-disabled': url('../../assets/icons/standard/list_disabled.svg'),
    'secondary--black--signpost-icon-disabled':
      url('../../assets/icons/standard/signpost_light_bg_disabled.svg'),
    'secondary--black--arrow-icon-disabled': url('../../assets/icons/standard/list_disabled.svg'),
    'secondary--black--signpost-icon-hover':
      url('../../assets/icons/standard/signpost_light_bg_hover.svg'),
    'secondary--black--list-icon-active': url('../../assets/icons/standard/list_light.svg'),
    'secondary--violet--list-icon': url('../../assets/icons/standard/list_light.svg'),
    'secondary--violet--signpost-icon': url('../../assets/icons/standard/signpost_light.svg'),
    'secondary--violet--arrow-icon': url('../../assets/icons/standard/list_light.svg'),
    'secondary--violet--list-icon-disabled': url('../../assets/icons/standard/list_disabled.svg'),
    'secondary--violet--signpost-icon-disabled':
      url('../../assets/icons/standard/signpost_light_bg_disabled.svg'),
    'secondary--violet--arrow-icon-disabled': url('../../assets/icons/standard/list_disabled.svg'),
    'secondary--violet--signpost-icon-hover':
      url('../../assets/icons/standard/signpost_violet_hover.svg'),
    'secondary--violet--list-icon-active': url('../../assets/icons/standard/list_light.svg'),
    'secondary--outline--list-icon': url('../../assets/icons/standard/list_light.svg'),
    'secondary--outline--signpost-icon': url('../../assets/icons/standard/signpost_light.svg'),
    'secondary--outline--arrow-icon': url('../../assets/icons/standard/list_light.svg'),
    'secondary--outline--list-icon-disabled': url('../../assets/icons/standard/list_disabled.svg'),
    'secondary--outline--signpost-icon-disabled':
      url('../../assets/icons/standard/signpost_dark_bg_disabled.svg'),
    'secondary--outline--arrow-icon-disabled': url('../../assets/icons/standard/list_disabled.svg'),
    'secondary--outline--signpost-icon-hover': url('../../assets/icons/standard/signpost_light.svg'),
    'secondary--outline--list-icon-active': url('../../assets/icons/standard/list_light.svg'),
    'secondary--text--list-icon': url('../../assets/icons/standard/list_light.svg'),
    'secondary--text--arrow-icon': url('../../assets/icons/standard/arrow_light.svg'),
    'secondary--text--list-icon-disabled': url('../../assets/icons/standard/list_disabled.svg'),
    'secondary--text--list-icon-active': url('../../assets/icons/standard/list_active.svg'),
    'secondary--text--arrow-icon-disabled': url('../../assets/icons/standard/arrow_disabled.svg'),
    'secondary--text--arrow-icon-hover': url('../../assets/icons/standard/arrow.svg'),
    'secondary--text--arrow-icon-active': url('../../assets/icons/standard/arrow_dark_active.svg'),
  ),
);

@mixin bg-mixin($typ: 'light') {
  @each $background-type, $bg-value in $background-types {
    @if $typ == $background-type {
      @each $key, $value in $bg-value {
        --#{$prefix}#{$key}: #{$value};
      }
    }
  }
  @content;
}

@mixin inter-font($x, $y) {
  font-family: 'Inter';
  @include font-size($x);
  line-height: $y;
  @content;
}
