@import '../../../scss/colors';
@import '../../../scss/mixins';
@import '../../../scss/fonts';
@import '../../../../scss/variables';

.featurettedata-section {
  &.data {
    background-color: $ts-background-secondary;
    padding: 3.75rem 1.25rem;
    gap: 2.25rem;
    @include respond-between(md, xl) {
      padding: 5rem 4rem;
    }
    @include respond-above(xl) {
      padding: 5rem 0;
    }

    .featurette-data {
      section {
        margin: 0 auto;
      }
      @include respond-below(lg) {
        margin-top: 2.25rem;
      }
    }
    .featurette-body {
      @include respond-above(lg) {
        padding: 5rem 0 0 0;
      }

      .sub-title,
      .richtext-wrapper {
        color: $ts-container-on-container-secondary-2;
      }
      .cta-wrapper {
        margin: 1.75rem 0 0 0;
      }
      .sub-title {
        @include label(small);
      }
      .title {
        @include headline(small);
        color: $ts-container-on-container-secondary-1;
        @include respond-above(md) {
          @include headline(5);
        }
      }
    }
    &.primary {
      background-color: $ts-background-primary;
      .featurette-body {
        .sub-title,
        .richtext-wrapper {
          color: $ts-container-on-container-primary-2;
        }
        .title {
          color: $ts-container-on-container-primary-1;
        }
        .cta-wrapper {
          margin: 1.75rem 0 0 0;
        }
      }
      .data-block {
        &__container {
          @include frostedGlass(extra-dark);
        }
        &__title,
        &__text {
          color: $ts-container-on-container-primary-1;
        }
      }
    }
    &.left {
      .featurette-body {
        @include tablet {
          margin: 2rem 0 1rem;
        }
      }
    }
  }
}
