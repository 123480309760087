@import '../../../../scss/colors';
@import '../../../../scss/mixins';

.dt-card {
  $block: &;

  display: flex;
  flex: 1;
  padding: 0;
  margin-bottom: 0;
  border-radius: 0.75rem;
  box-shadow: 0px 7.40924px 11.5868px rgba(198, 198, 197, 0.175929),
    0px 4.25707px 6.65734px rgba(198, 198, 197, 0.20529),
    0px 1.87365px 2.93007px rgba(198, 198, 197, 0.27);
  transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  cursor: pointer;
  overflow: hidden;

  &:hover,
  &:focus {
    z-index: 20;
    box-shadow: 0 11.7703px 18.4067px rgba(0, 0, 0, 0.154142);
  }

  @include respond-below(sm) {
    flex-direction: column;
    overflow: visible;
  }

  &--1 {
    @include respond-above(md) {
      min-height: 400px;
    }
  }

  &--2-1 {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.154142);
    border-radius: 0;
    border-bottom: 1px solid $ts-action-small-primary;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 rgba(0, 0, 0, 0.154142);
      // box-shadow: 7px 8.7703px 10.4067px rgba(0, 0, 0, 0.154142)
        .dt-card__arrow {
            left: 3px;
      }
    }
  }

  &--3 {
    .card-details {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      align-items: center;
      flex-wrap: wrap;

      @include respond-above(md) {
        margin-bottom: 24px;
      }

      .content-type {
        @include label(small);
        display: flex;
        align-items: center;
        text-transform: capitalize;
        gap: 8px;
      }
      
      .button,
      .date {
        white-space: nowrap;
        @include label(small);
      }
    }
  }

  &__image {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    border-radius: 0.75rem 0.75rem 0 0;

    @include respond-above(sm) {
      order: 1;
      max-width: 50%;
      border-radius: 0 0.75rem 0.75rem 0;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
    position: relative;
    padding: 24px 16px 16px;
    width: 100%;
    background: $ts-background-primary;
    border-radius: 0.75rem;
    .icon {
      width: 48px;
      height: 48px;
    }
    .next-arrow{
      margin-top:auto;
    }

    @include respond-above(md) {
      flex-direction: column;
      justify-content: flex-start;
      gap: 40px;
      padding: 32px 24px;
    }

    // #{$block}--img & {
    //   border-radius: 0 0 0.75rem 0.75rem;

    //   @include respond-above(sm) {
    //     border-radius: 0.75rem 0 0 0.75rem;
    //   }
    // }

    #{$block}--2-1 & {
      padding: 0 0 20px;
      border-radius: 0;

      @include respond-above(md) {
        flex-direction: row;
        gap: 10px;
        justify-content: space-between;
        padding: 0 0 32px;
      }
    }

    #{$block}--2-2 & {
      flex-direction: column;
      justify-content: space-between;
      gap: 14px;
      padding-bottom: 20px;

      @include respond-above(md) {
        min-height: 334px;
        padding-bottom: 24px;
      }
    }

    #{$block}--3 & {
      flex-direction: column;
      justify-content: space-between;
      gap: 18px;
      padding-bottom: 20px;

      @include respond-above(md) {
        min-height: 314px;
        padding-bottom: 24px;
      }
    }

    #{$block}--small & {
      @include respond-above(md) {
        min-height: 278px;
      }
    }
  }

  &__content {
    width: 100%;

    #{$block}--2-2 &,
    #{$block}--3 & {
      @include respond-above(md) {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__tag {
    @include label(small);
    margin-top: 0;
    margin-bottom: 15px;
    line-height: 12px;
    color: $ts-container-on-container-primary-2;

    @include respond-above(md) {
      margin-bottom: 27px;
    }
  }

  &__title {
    @include headline(small);
    color: $ts-container-on-container-primary-1;
    margin-bottom: 12px;

    #{$block}--2-1 & {
      @include label(medium);
    }

    #{$block}--2-2 {
      @include respond-above(md) {
        @include headline(medium);
      }
    }
  }

  &__text {
    @include body-copy(fine-print);
    margin: 0;
    color: $ts-container-on-container-primary-2;

    #{$block}--2-2 &,
    #{$block}--3 & {
      @include respond-above(md) {
        @include body-copy(small);
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }

  &__arrow {
    position: relative;
    flex-shrink: 0;
    width: 20px;
    left: 0px;
    transition: left 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);

    &:hover,
    &:focus {
      left: 3px;
    }

    #{$block}--2-1 & {
      @include respond-above(md) {
        margin-top: 5px;
      }
    }
  }
}
.dt-step2__card-container .dt-card--2-2:first-child{
  height: auto;
  width: 100%;
  object-fit: cover;
  object-position: top;
  aspect-ratio: 16/9;
  flex: 2;
}
