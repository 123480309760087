@import './../../../scss/colors';
@import './../../../scss/mixins';
.forms {
  &.hubspot-form {
    .newsletter {
      .title {
        margin: 0;
      }
      .hs-form-wrapper {
        form {
          .legal-consent-container {
            margin: 1rem 0 0;
            padding: 0;
            .hs-fieldtype-booleancheckbox {
              margin: 0;
            }
            ul.inputs-list li {
              span {
                display: flex;
                margin: 0;
                p {
                  top: unset !important;
                  a {
                    color: $mpg-color-gray-100 !important;
                    &:hover {
                      color: $mpg-color-gray-75 !important;
                    }
                  }
                }
              }
            }
          }
          input[type='submit'] {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
