@import '../../../scss/colors';
@import '../../../scss/mixins';

.filter-content {
  margin: 0 0 2.5rem 0; //Do not change
  @include respond-above(md) {
  }
  .title {
    @include headline(5);
    font-style: normal;
    font-weight: 400;
  }
  .search_header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    &.search_filter {
      margin: 1.25rem 0rem 2.25rem 0rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      @include respond-below(md) {
        margin: 1.25rem 0rem 0rem 0rem;
      }
      .search_filter_wrapper {
        // min-width: 80rem;
        @include respond-above(md) {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          gap: 20px;
          min-width: 70rem;
        }
        .search_filter__item {
          margin-top: 1rem !important;
          @include respond-above(md) {
            margin: 0;
          }
          .filterDropdown {
            @include respond-between(md, xl) {
              min-width: 220px;
              max-width: 220px;
            }
          }
        }
        .button {
          &.text-button:before {
            background-color: #fff;
          }
          &.text-button.small {
            width: 1.75rem;
            height: 1.75rem;
            text-align: center;
            border-radius: 0rem;
            margin-top: 5px;
          }
          &.button .icon__left {
            margin: 0 1rem 0 0;
            margin-left: 5px;
          }
          &.icon {
            &.icon__left {
              margin: 0;
            }
          }
        }
      }

      &__item {
        flex: 1;
        margin-bottom: 1rem;
      }

      @include respond-below(md) {
        flex-direction: column;
        justify-content: flex-start;
        gap: 1.25rem;
      }
    }
    .search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      order: -1;
      @include respond-above(md) {
        order: initial;
      }
      .filter_toggle {
        display: block;

        @include respond-above(md) {
          display: none;
        }
        &.open {
          .button {
            background-color: $black;
            color: $white;
            img {
              filter: invert(1);
            }
          }
        }
      }
      .searchFieldWrapper {
        border: 0;
        input {
          display: none;
        }
      }
    }
  }
  .search_values {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @include respond-below(md) {
      margin: 2rem 0;
    }
    @include respond-above(md) {
      margin-top: 2.25rem;
      height: 1rem;
    }
  }
  .search_footer {
    .search_count {
      color: $ts-background-on-primary-2;

      p {
        margin-bottom: 1rem;
      }
      span {
        color: $ts-background-on-primary-1;
      }
    }
    .sort_order {
      justify-content: flex-end;
    }
  }
  .search-title-main {
    span.resetseach {
      @include respond-below(md) {
        border-bottom: 1px solid $black;
      }
    }
  }
}
.search_sort {
  display: flex !important;
  justify-content: end !important;
  align-items: center !important;
  align-self: stretch !important;
}

// since its affecting the CardBlock Offerings we have commented the below label class
// label {
//   padding: 10px;
// }
.resetseach:hover {
  cursor: pointer;
}
@media screen and (max-width: 900px) {
  .filterDropdown.dropdown_closed .filterDropdownTitle {
    width: 335px !important;
    height: 45px !important;
    display: flex;
    padding: 0.69rem 1.25rem;
    align-items: center;
    gap: 5rem;
    align-self: stretch;
  }
  .filterDropdown {
    width: 20.938rem;
    // background-color: greenyellow !important;
    display: flex;
    padding: 8px 0px;
    align-items: center;
    gap: 5rem;
    align-self: stretch;
    width: auto;
  }
  .filter-content {
    padding: 2.5rem 1.25rem 2.5rem 1.25rem;
    margin: 0px !important;
    @include respond-below(md) {
      padding: 0;
    }
  }
  .filter-content .search_header.search_filter .search_filter_wrapper {
    margin-bottom: 1.5rem;
    width: 100%;
  }
  .search_filter__item {
    margin: 1rem 0rem 1.5rem 0rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }

  .button.text-button:before {
    display: none;
  }
}

.search-title-main {
  width: 100%;
  align-items: center;
  gap: 20px;
  @include respond-above(md) {
    display: flex;
  }
}

.search-title {
  color: var(--tokens-background-on-primary-1, #000);

  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.5px;
  @include respond-below(md) {
    font-size: 24px;
  }
}
.search-title-link {
  color: var(--tokens-accent-1-primary, #5c4bb9);
  @include respond-below(md) {
    font-size: 24px;
  }
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.5px;
}
.search-title-main a {
  display: flex;
  padding-top: 2px;
  align-items: flex-start;
  gap: 10px;
}
