$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1340px,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1400px,
);

// Bootstrap
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/modal';

// Partials
@import 'fonts';
@import 'mixins';
// @import 'helpers';
// @import 'typography';

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints);
@include _assert-ascending($container-max-widths, '$container-max-widths');

$grid-columns: 12;
$grid-gutter-width: 1rem;
$enable-grid-classes: true;

* {
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.5;
  font-family: 'Inter', Helvetica, Arial, 'Droid Sans', 'Arimo', sans-serif;
  font-weight: 400;
}

body {
  overflow-x: hidden;
  color: $black;
  // padding-top: 96px;
  @include tablet {
    // padding-top: 65px;
  }
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

// .container {
//     padding-right: 1.25rem;
//     padding-left: 1.25rem;
//     @include respond-above(sm) {
//         padding-right: 1rem;
//         padding-left: 1rem;
//     }
// }

img {
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}
h3 {
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: -0.1px;
}
