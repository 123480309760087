@import './../../../scss/mixins';
@import './../../../scss/fonts';
@import './../../../scss/colors';
@import './../../../../scss/variables';

#contact-section {
  scroll-behavior: smooth;
}

/* Create two equal columns that floats next to each other */
.contact-column-6 {
  float: left;
  width: 50%;
}
.second-column-6 {
  padding-left: 1.5rem;
  @include mobile {
    padding-left: 0;
  }
}
/* Clear floats after the columns */
.contact-row:after {
  content: '';
  display: table;
  clear: both;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .contact-column-6 {
    width: 100%;
  }
}
.contact-checkbox-label {
  font-family: $checkbox-font-family;
  font-weight: 400 !important;
  line-height: 1.16rem;
  &.Bottom{
    &:before{
      bottom: 0.125rem;
      top:unset;
    }
    &:after{
      top:unset;
      bottom: 8px;
    }
  }
}
.form-block label {
  font-weight: 600 !important;
}
.contact-submit {
  margin-top: 1.25rem !important;
}
.form-button {
  padding-top: 1.5rem !important;
}

.contact-form {
  display: flex;
  flex-direction: column;
  & .contact-form-footer {
    margin-top: 0;
  }
}
section .contact {
  padding: 5rem;
}
.contact {
  padding: 5rem 1.25rem;
  @include mobile {
    padding: 2.5rem 1.25rem 1.25rem;
  }
  h2 {
    margin-bottom: 0.75rem;
  }
  .contact-checkbox-label {
    font-family: $checkbox-font-family;
    font-weight: 400 !important;
    line-height: 1.16rem;
    font-size: 0.75rem;
    margin-left: -0.875rem;
  }
  .section-contact-form .form-block label {
    font-weight: 600;
  }
  .form-block {
    margin-bottom: 1.5rem;
  }
  .section-contact-form .form-block{
  &.formChk{
    right:1.5%;
    label{
   font-size: 1rem;
   font-weight: 600 !important;
   font-family: $font-family-1;
    }
    p.error-msg{
      margin-left:1.5%;
    }
  }
}
}
@media (min-width: 992px) and (max-width: 1398px) {
  .contact {
    padding: 5rem 5rem;
  }
}
.form-block select,
.form-block select option {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
}
.form-submit-error-msg{
  color : $ts-alert-error;
}
.form-block select {
  &.invalid.error {
    box-shadow: inset 3px 4px 12px $form-error-box-border-shadow-color;
    border-color: $form-error-box-border-color;
  }
}
