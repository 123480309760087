@import '../../../../scss/colors';
@import '../../../../scss/mixins';

.dt-footer {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    @include respond-above(md) {
      flex-direction: row;
      justify-content: flex-end;
      gap: 32px;
    }
  }

  &__text {
    @include label(medium);
    margin: 0;
    color: $ts-background-on-secondary-1;
  }

  &__cta-container {
    display: flex;
    gap: 40px;

    @include respond-above(md) {
      gap: 8px;
    }
  }
}
