@import '../../../../scss/colors';
@import '../../../../scss/mixins';

.db-statistic {
  $block: &;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;

  &--s {
    width: 140px;
    height: 140px;
  }

  &--blue {
    color: $ts-accent-3-on-accent-3-primary;
    background-color: $ts-accent-3-primary;
  }

  &--green {
    color: $ts-accent-2-on-accent-2-primary;
    background-color: $ts-accent-2-primary;
  }

  &--violet {
    color: $ts-accent-1-on-accent-1-primary;
    background-color: $ts-accent-1-primary;
    .db-statistic__pre-text,
    .db-statistic__value,
    .db-statistic__post-text {
      color: $ts-background-on-secondary-1;
    }
  }

  &__container {
    #{$block}--s & {
      padding: 22px 12px;
    }
    #{$block}--s#{$block}--percentage & {
      padding-top: 30px;
    }
    #{$block}--s#{$block}--ranking & {
      padding: 28px 20px 20px;
    }

    #{$block}--m & {
      padding: 54px 24px 42px;
    }
    #{$block}--m#{$block}--secondary & {
      padding: 40px 24px 28px;
    }
    #{$block}--m#{$block}--ranking & {
      padding:28px 24px;
    }
  }

  &__primary-container {
    display: flex;
    flex-direction: column;

    #{$block}--s & {
      gap: 4px;
    }
    #{$block}--s#{$block}--ranking & {
      gap: 12px;
    }

    #{$block}--m & {
      gap: 8px;
    }
    #{$block}--m#{$block}--secondary & {
      gap: 4px;
    }
    #{$block}--m#{$block}--secondary#{$block}--percentage & {
      gap: 8px;
    }
    #{$block}--m#{$block}--ranking & {
      gap: 8px;
    }
    #{$block}--m#{$block}--secondary#{$block}--ranking & {
      gap: 0;
    }
  }

  &__pre-text {
    @include label(small);
    margin: 0;

    #{$block}--m & {
      @include label(medium);
    }
    #{$block}--m#{$block}--secondary & {
      @include label(small);
    }
  }

  &__value-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 4px;

    #{$block}--m#{$block}--secondary & {
      align-items: center;
    }
  }

  &__value {
    @include headline(2, false);
    display: flex;
    align-items: baseline;
    margin: 0;

    #{$block}--m & {
      font-size: 5.5rem;
      line-height: 5.5rem;
      letter-spacing: -3.5px;
    }
    #{$block}--m#{$block}--ranking & {
      font-size: 5.5rem;
    }
  }

  &__percentage {
    @include label(medium);
    line-height: normal;

    #{$block}--m & {
      @include headline(5, false);
    }
  }

  &__value-suffix {
    @include headline(medium, false);

    #{$block}--m & {
      font-size: 1.75rem;
      line-height: 2rem;
      letter-spacing: -1.5px;
    }
    #{$block}--m#{$block}--secondary & {
      font-size: 2.5rem;
    }
  }

  &__change-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    margin-bottom: 2px;
    padding: 7px 5px;
    border-radius: 34px;
    color: $ts-action-on-action-secondary;
    background: $ts-action-secondary;

    #{$block}--violet & {
      color: $ts-action-on-action-primary;
      background: $ts-action-primary;
    }

    #{$block}--m & {
      gap: 9px;
      margin-bottom: 5px;
      padding: 9px 6px;
    }
  }

  &__change-icon {
    width: 9px;
    color: $ts-alert-success;

    &--negative {
      transform: scaleY(-1);
      color: $ts-alert-error;
    }

    #{$block}--m & {
      width: 12px;
    }
  }

  &__change-value {
    @include label(small);
    margin: 0;
    line-height: 12px;

    #{$block}--m & {
      @include label(medium);
    }
  }

  &__post-text {
    @include label(medium);
    margin: 0;

    #{$block}--ranking & {
      @include label(small);
    }

    #{$block}--m & {
      @include headline(small, false);
    }
    #{$block}--m#{$block}--secondary & {
      @include label(medium);
    }
  }

  &__secondary-container {
    display: flex;
    align-items: center;
    padding: 4px;
    width: 100%;
    gap: 12px;
    margin-top: 12px;
    border-radius: 24px;
    background: $ts-action-secondary;
    color: $ts-action-on-action-secondary;

    #{$block}--violet & {
      background: $ts-action-primary;
      color: $ts-action-on-action-primary;
    }

    #{$block}--number & {
      gap: 8px;
    }

    #{$block}--ranking & {
      margin-top: 17px;
    }
  }

  &__secondary-value {
    @include label(medium);
    padding: 8px;
    border-radius: 44px;
    background: $ts-action-small-primary;

    #{$block}--number & {
      @include label(small);
      line-height: 12px;
    }

    #{$block}--violet & {
      background: $ts-action-small-secondary;
    }
  }

  &__secondary-desc {
    @include label(small);
  }
}
